<template>
<div v-if="categories.length > 0" class="flex justify-center items-center flex-wrap p-2">
    <p @click="filterDocuments('')" class="cursor-pointer text-lg" :style="'color:' + isActive('')">All</p>
  <div v-for="category in categories" :key="category.id" class="m-6">
    <p @click="filterDocuments(category.id)" class="cursor-pointer text-lg" :style="'color:' + isActive(category.id)">{{category.name}}</p>
  </div>
</div>
<div v-else class="flex justify-center items-center flex-wrap">
  No Categories
</div>

<div :style="'background-color:' + backgroundColor" class="w-full p-4 min-h-screen">
    <div class="flex justify-center items-center flex-wrap">
      <div v-for="document in filteredDocuments" :key="document.id" class="m-4 w-72 rounded-xl shadow-lg shadow-black bg-white" :style="'min-height:22rem;'">
        <a v-if="document.activated" :href="document.link" target="_blank" class="">
          <div v-if="document.activated" class="text-left m-auto h-full justify-center items-center">
            <div class="w-full h-44 overflow-hidden"> 
              <img v-if="document.media !='/'" :src="baseURL + document.media" class="mr-2 w-full h-full rounded-t-xl" alt="" style="object-fit: cover;">
              <p class="mr-2 w-full mt-16 ml-24" v-else>No Picture</p>
            </div>
            <p class="m-3 mx-6" :style="';color:' + getColor(document.category.id)">{{document.category.name}}</p>
            <p class="m-3 mx-6 text-black font-semibold">{{document.name}}</p>
          </div>
        </a>
      </div>
    </div>
</div>
</template>

<script>
import apiManager from "../datas/apiManager"; 
import store from '../store/index'
export default {
  name: "Library",
  components: {

  },

  data: () => ({
    backgroundColor : '#f2f9ff',
    categories : [],
    documents : [],
    numberColor : 0,
    filter: "",
    color : {
      0 : '#00CB49',
      1 : '#0287D1',
      2 : '#133795',
      3 : '#A83C42',
      4 : '#16E7CF',
      5 : '#004C19',
      6 : '#EF5FA7',
    },
    backColor : {
      0 : 'rgba(0, 203, 73, 0.10)',
      1 : 'rgba(2, 135, 209, 0.10)',
      2 : 'rgba(19, 55, 149, 0.10)',
      3 : 'rgba(168, 60, 66, 0.10)',
      4 : 'rgba(22, 231, 207, 0.10)',
      5 : 'rgba(0, 76, 25, 0.10)',
      6 : 'rgba(239, 95, 167, 0.10)',
    }
   
  }),
  methods: {
    refreshData() {
      apiManager
        .getActiveRound()
        .then((response) => {
          this.activeRound = response.data.activeRound
          store.commit('account/_set', { k: 'active_round', v: response.data.activeRound })
          store.commit('account/_set', { k: 'date_now', v: response.data.dateNow })
          store.commit('account/_set', { k: 'library_cost', v: response.data.activeRound != null ? response.data.activeRound.library_cost : null })

          if(this.user) {
            let data = {
              group_id : this.user.group.id
            };

            apiManager.getLoggedUser(data).then((response) => {
              this.$store.state.account.data.group.malus = response.data.malus
            });
          }
          
      })

      
    },
    isActive(categoryId) {
      if (categoryId == this.filter) {
        return '#133795;text-decoration: underline;'
      }
      return '#BDBDBD'
    },
    filterDocuments(categoryId) {
      this.filter = categoryId

      if (categoryId == '') {
        this.backgroundColor = '#f2f9ff'
      } else {
        this.backgroundColor = this.getBackColor(categoryId)
      }
    },
    getColor (categoryId) {
      let color = 'black'
      this.categories.forEach(category => {
        if (categoryId == category.id) {
          color = category.color
        }
      });

      return color
      // return this.color[index] ? this.color[index] : this.color[(Math.floor(Math.random() * (11 - 0)) + 0)]
    },
    getBackColor (categoryId) {
      let color = 'black'
      this.categories.forEach(category => {
        if (categoryId == category.id) {
          color = category.backColor
        }
      });

      return color
      // return this.color[index] ? this.color[index] : this.color[(Math.floor(Math.random() * (11 - 0)) + 0)]
    }
  },

  computed: {
    user() {
      return this.$store.state.account.data;
    },
    library_cost () {
      return this.$store.state.account.library_cost
    },
    score_id () {
      return this.$store.state.account.score_id
    },
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return '';
      }
    },
    filteredDocuments() {
      let documentsList = this.documents
      return Object.values(documentsList).filter(c => {
        if(this.filter == '') return true;
        return c.category.id == this.filter;
      })
    },
  },
  created() {

    this.refreshData()

		this.interval = setInterval(() => {
			this.refreshData()
		}, 7000)


    if(this.user) {
      let data = {
        group_id : this.user.group.id
      };

      apiManager.getLoggedUser(data).then((response) => {
        this.$store.state.account.data.group.malus = response.data.malus
      });
    }
   

    apiManager.getLibrary()
    .then((response) => {
      this.categories = response.data.data
      for (const key in this.categories) {
        this.categories[key].color = this.color[key] ? this.color[key] : this.color[(Math.floor(Math.random() * (11 - 0)) + 0)]
        this.categories[key].backColor = this.backColor[key] ? this.backColor[key] : this.backColor[(Math.floor(Math.random() * (11 - 0)) + 0)]
      }
    })


    apiManager.getDocuments()
    .then((response) => {
      this.documents = response.data.data
      for (const key in this.documents) {
        this.documents[key].color = this.color[key] ? this.color[key] : this.color[(Math.floor(Math.random() * (11 - 0)) + 0)]
        this.documents[key].backColor = this.backColor[key] ? this.backColor[key] : this.backColor[(Math.floor(Math.random() * (11 - 0)) + 0)]
      }
    })

    if(this.user.group.malus == null || this.user.group.malus == 0) {

      let data = {
        malus: this.user.group.malus,
        library_cost: this.library_cost,
        score_id: this.score_id,
        team_id : this.user.id
      };
      this.$store.state.account.data.group.malus = this.library_cost

      apiManager.payLibrary(data).then((response) => {
        console.log(response);
				// store.commit('account/_set', { k: 'pay', v: 1 })
      });
    }

  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  mounted () {
  },
  watch: {
    
  }

};
</script>

<style scoped>


</style>
