<template>
	<!-- <div v-if="action" class="my-4 flex w-full" style=""> -->
	<div :class="!isList ? 'my-3 p-3 rounded-xl border flex flex-col justify-between' : 'mb-2 flex w-full px-4'"  :style="!isList ? ('width:23rem;border-color:rgba(2,135,209,0.07);background-color:' + backgroundColor) : ('background-color:' + backgroundColor)">


		<div :class="!isList ? '' : 'flex'" :style="!isList ? '' : 'width:80%'">
			<div v-if="coalition && coalition == 1"
			:style="!isList ? '' : 'width:8.75%;border-color:white'"
			class="px-3 flex justify-center">
				<div class="lg:flex w-max m-auto">
					<div>
						<input type="checkbox"
						:id="'positive' + action.data.id"
						name="positive"
						value="positive"
						class="demo4 demosad"
						:class="reading != true ? ' cursor-pointer' : 'cursor-default'"
						:disabled="reading == true"
						@change="changeCoalition"
						:checked="check.positive">
						<label :for="'positive' + action.data.id" class="text-xl" 
							:class="reading != true ? ' cursor-pointer' : 'cursor-default'">
							<img :src="'/img/coalition' + (check.positive ? 'b' : 'g') + '.svg'" alt="" class="w-8">
						</label>
					</div>

					<div class="lg:ml-2">
						<input type="checkbox"
						:id="'negative' + action.data.id"
						name="negative"
						value="negative"
						:disabled="reading == true"
						class="demo4 demosad"
						:class="reading != true ? ' cursor-pointer' : 'cursor-default'"
						@change="changeCoalition"
						:checked="check.negative">
						<label :for="'negative' + action.data.id" class="text-xl" 
							:class="reading != true ? ' cursor-pointer' : 'cursor-default'">
							<img :src="'/img/noCoalition' + (check.negative ? 'b' : 'g') + '.svg'" alt="" class="w-8">
						</label>
					</div>
				</div>
			</div>

			<div v-if="isList" :class="'px-3 text-center flex justify-center'"
				:style="(!isList ? '' : 'width:6.25%')">
				<p class="m-auto font-semibold">{{ action.index + 1 }}</p>
			</div>

			<div class="py-6 px-3 flex justify-center" :style="(!isList ? '' :  (coalition && coalition == 1 ? 'width:20%' : 'width:28.75%'))">
				<Multiselect v-model="tagValue"
				class="m-auto"
				mode="tags"
				@change="changeTag"
				:disabled="isSubmit == 1 || !isLived || reading"
				:closeOnSelect="false"
				:searchable="true"
				:options="tagsList"/>
			</div>


			<div :class="!isList ? 'flex w-full mt-3' : 'py-6 px-3 flex justify-center'" :style="(!isList ? '' : 'width:65%')">
				<div v-if="!isList" class="font-semibold h-full mr-3" style="color:#0287D1">{{ action.index +1 }}</div>
				<p class="my-auto mr-4 text-left w-full">{{ action.data.name }}</p>
			</div>
		</div>


		<div :class="!isList ? 'mt-4' : 'flex'" :style="!isList ? ''  : 'width:20%'">
			<div :style="!isList ? '' : 'width:50%;'" class="flex justify-center">
				<div class="flex w-max m-auto">
					<div @click="minus()"
						class="h-6 w-6 lg:h-8 lg:w-8 my-auto rounded-full px-2 noFocus"
						:style="(isSubmit == 1 || !isLived || coalition == 1 || reading == true ? '' : 'cursor: pointer;') + 'background-color: ' + minusColor">
						<p class="text-center lg:text-xl text-white lg:leading-7">-</p>
					</div>
					<input class="h-10 w-8 lg:w-12 my-auto p-2 text-center"
						style="background-color: #11ffee00;"
						type="number"
						min="0"
						:disabled="isSubmit == 1 || !isLived || coalition == 1 || reading == true"
						:name="action.data.id"
						:id="action.data.id"
						:value="actionValue"
						@change="changeAction"/>
					<!-- @input="changeAction" -->
					<div @click="plus()"
						class="h-6 w-6 lg:h-8 lg:w-8 my-auto rounded-full px-2 noFocus"
						:style="(isSubmit == 1 || !isLived || coalition == 1 || reading == true ? '' : 'cursor: pointer;' )+ 'background-color: ' + plusColor">
						<p class="text-center lg:text-xl text-white lg:leading-8">+</p>
					</div>
				</div>
			</div>
			<div :style="!isList ? '' : 'width:50%;'" class="flex justify-center" :class="!isList ? 'mt-2' : ''">
				<div class="w-max m-auto" :class="!isList ? 'flex' : ''">
					<img v-if="action.data.example"
					class="m-auto w-6"
					:class="!isList? 'mr-2' : 'mb-2'"
					style="cursor: pointer;color:#0287D1"
					title="Examples"
					@click="openExamples()"
					src="/img/eye.svg"
					alt="">
					<img class="m-auto w-6"
					:class="action.data.example ? '' : ''"
					style="cursor: pointer;color:#0287D1"
					title="History"
					@click="openHistory()"
					src="/img/graph.svg"
					alt="">

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
	data: () => ({
		initialized : false,
		timeout : null,
		tagValue: [],
		actionValue: 0,
		tag: '',
		answer: '',
		bgColor: '#00cb49',
		bgColorInput: 'white',
		check: {
			positive: false,
			negative: false
		},
	}),
	components: {
		Multiselect
	},
	emits: ['changeTag', 'changeAction', 'historyAction', 'exampleAction', 'addCoalition', 'removeCoalition'],
	props: {
		action: Object,
		tagsList: Array,
		restPool: Number,
		pool: Number,
		isLived: Boolean,
		allPoints: Boolean,
		coalition: [Boolean, Number],
		coalitions: [Array, Object],
		reading : [Boolean],
		isList : Boolean,
		triggerAction: Number,
	},
	methods: {
		changeCoalition (event) {
			
			if (this.isSubmit != 1 && this.isLived && this.reading != true) {
				if (this.loadingData) {
					console.log('waiting for send coalition')
					window.setTimeout(this.changeCoalition, 100, event)
					return;
				}

				if (event.target.checked == true) {

					if (this.coalitions[event.target.name].length < 3) {
						let action = {
							type: event.target.name,
							id: this.action.data.id,
							name: this.action.data.name
						}
						this.$emit('addCoalition', action)
						this.check[event.target.name] = true

					}
					else {
						event.target.checked = false
						this.check[event.target.name] = false
					}

				}
				else {
					this.check[event.target.name] = false
					let action = {
						type: event.target.name,
						id: this.action.data.id
					}
					this.$emit('removeCoalition', action)

				}
			}
		},
		openHistory () {
			this.isPopup = true
			this.$emit('historyAction', this.action.data.id, this.action.data.value)
		},
		openExamples () {
			this.isPopupExp = true
			this.$emit('exampleAction', this.action.data.id, this.action.data.value)
		},
		resetPoints () {
			this.actionValue = 0
			// this.changeAction()
		},
		changeTag (e) {
			let data = {
				action: this.action.data.id,
				tag: e
			}

			this.$emit('changeTag', data)
		},
		minus () {
			if (this.actionValue > 0 && this.isSubmit != 1 && this.isLived && this.coalition != 1 && this.reading != true) {
				let data = {
					target_id: this.action.data.id,
					answer: parseInt(this.actionValue) - 1,
				}
				this.$emit('changeAction', data, false)
				
			}
		},
		plus () {
			if ((this.restPool - 1) >= 0 && this.isSubmit != 1 && this.isLived && this.coalition != 1 && this.reading != true) {
				let data = {
					target_id: this.action.data.id,
					answer: parseInt(this.actionValue) + 1,
				}
				this.$emit('changeAction', data, false)
			}

		},
		changeAction (e) {
			if (e && (this.restPool + this.actionValue - e.target.value) < 0  || e.target.value < 0 || e.target.value % 1 != 0) {
				e.target.value = this.actionValue
				return
			}
			let data = {
				target_id: this.action.data.id,
				answer: e.target.value,
			}
			this.$emit('changeAction', data, false)

		}
	},
	computed: {
		loadingData() {
			return this.$store.state.loadingData;
		},
		plusColor () {
			if (this.isSubmit == 1 || !this.isLived || this.coalition == 1  || this.reading == true) {
				return '#D5D5D5'
			}
			if (this.restPool <= 0) {
				return '#D5D5D5'
			}
			return '#00CB49'
		},
		minusColor () {
			if (this.isSubmit == 1 || !this.isLived || this.coalition == 1 || this.reading == true) {
				return '#D5D5D5'
			}
			if (this.actionValue <= 0) {
				return '#D5D5D5'
			}
			return '#FF644E'
		},
		backgroundColor () {
			let color = 'white;'
			if (this.actionValue != 0 && this.actionValue != null && !isNaN(this.actionValue)) {
				color = '#f0faff;'
			}

			return color
		},
		user () {
			return this.$store.state.account.data
		},
		isSubmit() {
			return this.$store.state.account.is_submit
		}
	},
	beforeUpdate () {
		if (this.action.data.tags) {
			this.tagValue = this.action.data.tags
		}
		if (this.action.data.value != null) {
			this.actionValue = this.action.data.value
		}


		for (const key in this.coalitions.positive) {
			if (this.coalitions.positive[key].id == this.action.data.id) {
				this.check.positive = true
				break;
			} else {
				this.check.positive = false
			}
		}
		for (const key in this.coalitions.negative) {
			if (this.coalitions.negative[key].id == this.action.data.id) {
				this.check.negative = true
				break;
			} else {
				this.check.negative = false
			}
		}
	},
	watch : {
		actionValue : {
			handler (newValue, oldValue) {
				if (newValue == oldValue || !this.initialized || this.reading) {
					return
				}
			
				let context = this
				if (this.timeout) {
					clearTimeout(this.timeout)
				}

				this.timeout = setTimeout (() => {
					let data = {
						target_id: context.action.data.id,
						answer: context.actionValue,
						// action: 'add'
					}
					this.$emit('changeAction', data, true)
				}, 2000)
			}
		},
	},
	created () {
		if (this.action.data.tags) {
			this.tagValue = this.action.data.tags
		}
		if (this.action.data.value != null) {
			this.actionValue = this.action.data.value
		}
		if (this.actionValue != 0 && this.actionValue != null && !isNaN(this.actionValue)) {
			this.bgColorInput = 'rgba(2,135,209,0.01)'
		}

		for (const key in this.coalitions.positive) {
			if (this.coalitions.positive[key].id == this.action.data.id) {
				this.check.positive = true
			}
		}

		for (const key in this.coalitions.negative) {
			if (this.coalitions.negative[key].id == this.action.data.id) {
				this.check.negative = true
			}
		}
		this.initialized = true

	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="../main.css"></style>
<style>


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* .multiselect-tags{
	max-width: 250px;
} */

.multiselect-tag {
	/* background-color: v-bind(bgColor) !important; */
	background-color: #00cb49 !important;
	color: white !important;
	padding: 3px 10px;
    display:flex;
    max-width:150px;
    word-break:break-all;
	white-space: normal

}

.multiselect-tag-remove {
	display: inline;
	width:10px;
}

.shadow-border {
	box-shadow: 0px 0px 2px #888 inset;
}

.multiselect {
	background-color: v-bind(bgColorInput) !important;
}

.multiselect-tags-search {
	background-color: v-bind(bgColorInput) !important;
}


input[type="checkbox"].demo4 {
	display: none;
}

.noFocus {
	-moz-user-select: none; /* Firefox */
	-webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
	-ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
	user-select: none; /* Propriété standard */
}
</style>

