<template> 
  <LineChart ref="linechart" :chartData="testData" :options="options" :height="height"/>
</template>

<script>
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

export default {
    components: { LineChart },
    props : {
        dataChart: {
            type: Array, Object,
        },
        numberRounds : Number,
    },
    data: () => ({
        data : [],
        colors : {
            1 : '#0287D1',
            2 : '#00CB49',
            3 : '#16E7CF',
            4 : '#FF644E',
            5 : '#EF5FA7',
            6 : '#F0A891',
            7 : '#FF6A6C',
            8 : '#A83C42',
            9 : '#004C19',
            10 : '#133795',
        }
    }),
    methods : {
        getColor (index) {
            return this.colors[index] ? this.colors[index] : this.colors[(Math.floor(Math.random() * (10 - 0)) + 0)]
        }
    },
    computed : {
        sortedTeams() {
            let sortedData = this.data
            return sortedData.sort((a,b) => {
            let modifier = 1;
            if(a['label'] < b['label']) return -1 * modifier;
            if(a['label'] > b['label']) return 1 * modifier;
            return 0;
            });
        },
        user() {
            return this.$store.state.account.data;
        },
        labeRound() {
            let labels = []
            for (let index = 0; index < this.numberRounds; index++) {
                labels.push('Round ' + (index+1))
                
            }
            return labels
        },
        testData() {
            let data = {
                labels: this.labeRound,
                datasets: this.sortedTeams,
            }
            return data
        },
        options() {
            let options = {
                responsive: true,
                plugins: {
                    title: {
                        display: false,
                    }, 
                    datalabels: {
                        backgroundColor: function (context) {
                        return context.dataset.borderColor;
                        },
                        borderRadius: 4,
                        color: "white",
                        font: {
                        weight: "bold",
                        },
                        padding: 4,
                    }, 
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Global Score'
                        },
                        ticks: {
                            stepSize: 100
                        }
                    }
                },
            }
            return options
        },
        height() {
            return parseInt(500)
        }
        
    },
    created() {
        Chart.register(ChartDataLabels);

        let teams = [];
       
        this.dataChart.forEach(round => {
            round.scores.forEach(score => {
                if (teams[score.group.name] !== undefined) {
                    teams[score.group.name].push(score.global)
                } else {
                    teams[score.group.name] = [score.global]
                }
            });
            
        });
        
        let data = [];

        

        for (const key in teams) {

            for (let index = 0; index < this.numberRounds; index++) {
                if (teams[key][index] == undefined) {
                    teams[key][index] = null
                }
                
            }
           

            data.push(
                {
                    label: key,
                    data: teams[key],
                    borderWidth: (this.user && this.user.login == key ? 5 : 2),
                    borderColor: this.getColor(Object.keys(teams).indexOf(key)+1),
                    fill: false,
                    tension: 0.3
                }
            )
        }

        this.data = data
        
    }
};
</script>
