import apiManager from './apiManager'
import store from '../store/index'

export default {
  login (login, password, onSuccess = null, onError = null) {
    apiManager.login(login, password).then(response => {
      store.commit('account/_set', { k: 'data', v: response.data.data })

      //resfresh api token from user
      apiManager.refreshApiToken()

      // execute success callback from login page
      if (onSuccess) {
        onSuccess()
      }
    }).catch(() => {
      if (onError) {
        onError()
      }
    })

  },
  logout () {
    store.dispatch('_deleteAlls')
  },
  
}
