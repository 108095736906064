import axios from 'axios'
import store from '../store/index'
import taskManager from './taskManager'
import router from '../router/index'

const baseURL = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_API_URL + '/api'
  : 'http://127.0.0.1:8000/api'

let api_token = store.state.account.data != null ? store.state.account.data.api_token : ''

const api = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    "Authorization" : 'Bearer ' + api_token
  }
})

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  catch401(error)
  return error;
});

function catch401(error) {
  if (error.response.status == 401) {
    taskManager.logout()
    router.push({ name: 'Login', params: { message: 'A new user has logged into your account' } })
  
  }
}

export default {
    refreshApiToken() {
      api.defaults.headers["Authorization"] = 'Bearer ' + store.state.account.data.api_token
    },
    login (login, password) {
      return axios('login', { baseURL, method: 'post', data: { login, password } })
    },
    getSettings () {
      return api.get(`/settings`)
    },
    getActiveRound () {
      return api.get(`/active-round`)
    },
    getLastRound () {
      return api.get(`/last-round`)
    },
    getForm () {
      return api.get(`/form`)
    },
    getScore (idUser) {
      return api.get(`/score/${idUser}`)
    },

    // getScoreAdmin (idUser) {
    //   return api.get(`/score/${idUser}/admin`)
    // },
    getLibrary () {
      return api.get(`/library`)
    },
    getDocuments () {
      return api.get(`/documents`)
    },
    getTime () {
      return api.get(`/time`)
    },
    getRankingAdmin () {
      return api.get(`/score/ranking`)
    },
    getRanking (teamId) {
      return api.get(`/score/ranking/${teamId}`)
    },
    getHistory (teamId) {
      return api.get(`/score/history/${teamId}`)
    },
    // getActiveRound () {
    //   return api.get(`/active`)
    // },
    submit (data) {
      return api.post(`/submit`, data)
    },
    getLoggedUser(data) {
      return api.post(`/getLoggedUser`, data)
    },
    calculate (data) {
      // let baseURLPost = process.env.NODE_ENV === 'production'
      // ? window.location.origin
      // : 'http://127.0.0.1:8000'

      // let config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Origin' : 'http://localhost:8080',
      //     "Authorization" : 'Bearer ' + api_token 
      //   }
      // }
      
      return api.post(`/calculate`, data)
      // return axios.post(baseURL + "/calculate", data, config)
          
    },
    createDiary(data) {
      return api.post(`/createDiary`, data)
    },
    getLastDiaries(data) {
      return api.post(`/getLastDiaries`, data)
    },
    // updateCalculate (data) {
    //   return api.put(`/calculate`, data)
          
    // },
    payLibrary (data) {
   
      return api.post(`/pay/library`, data)
      // return axios.post(baseURL + "/calculate", data, config)
          
    },
}