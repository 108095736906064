<template>
    <div class="flex mr-3" style="">
        <p class="px-1 font-semibold mr-3" v-if="activeRound && activeRound.name"> 
            ROUND
            <span class="text-white p-1 px-2 rounded-full" style="background-color:#0287D1" >
                {{activeRound.number}}
            </span>
        </p>
        <p>
            {{time}}
        </p>
    </div>
</template>

<script>
import store from '../store/index'
import apiManager from "../datas/apiManager"; 
export default {
    data () {
        return {
            timerLoop: null,
            countDown : 0,
            TIME_LIMIT : this.activeRound.time*60,
            timePassed : 0,
            timerInterval : null,
        }
    },
    props: {
        activeRound :  [String, Number, Object],
        dateNow :  [String, Number, Object],
        // actualTimer : [String, Number, Object]
    },
    computed: {
        score_id () {
            return this.$store.state.account.score_id
        },
        time () {
            var sec_num = parseInt(this.countDown, 10); // don't forget the second param
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}

            // let minutes = (Math.floor(this.countDown/ 60)).toString().padStart(2, '0')
            // let seconds = (this.countDown % 60).toString().padStart(2, '0')

            return hours + ' : ' + minutes + ' : ' + seconds
        },
    },
    methods: {
        stopTimer() {
            clearInterval(this.timerLoop)
            let data = {
                score_id : this.score_id
            }
            apiManager.submit(data).then(() => {
                // console.log(response);
                store.commit('account/_set', { k: 'is_submit', v: 1 })
            })
        },
        countDownTimer () {
            this.timerLoop = setInterval(() => {
                this.countDown--

                if (this.countDown <= 0) {
                    this.countDown = 0
                 this.stopTimer()
                }
              
                store.commit('account/_set', { k: 'actual_timer', v: this.countDown })
            }, 1000)
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${minutes}:${seconds}`;
        },
    },
    created () {
        this.countDownTimer()
        let tNow = this.dateNow

        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)

        if (this.countDown <= 0) {
            this.countDown = 0
            this.stopTimer()
        }
    
    },
    beforeUnmount () {
        clearInterval(this.timerLoop)
    },
    watch: {
    dateNow () {
        let tNow = this.dateNow

        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)
        
        if (this.countDown <= 0) {
            this.countDown = 0
            this.stopTimer()
        }
        
        this.TIME_LIMIT = this.activeRound.time*60
    },
    'activeRound.id'() {
        this.timerLoop = null
        this.countDown = 0
        this.FULL_DASH_ARRAY = 283
        this.TIME_LIMIT = this.activeRound.time*60
        this.timePassed = 0
        this.timerInterval = null
        

        let tNow = this.dateNow
        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)

        this.countDownTimer()

        // const { alert, warning, info } = this.COLOR_CODES;
        // alert
        // warning

        // document
        // .getElementById("base-timer-path-remaining")
        // .classList.remove(info.color);
        // document
    }
  }



}
</script>

<style scoped>
</style>