<template>
    <div v-if="dateNow != null" class="base-timer bg-white rounded-full" style="position: absolute;bottom: 2rem;">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
            <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
            <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                :class="'base-timer__path-remaining ' + remainingPathColor"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
                "
            ></path>
            </g>
        </svg>
        <div id="base-timer-label" class="base-timer__label"> 
            <p class="px-1 font-semibold" v-if="activeRound && activeRound.name"> 
                ROUND
                <span class="text-white p-1 px-2 rounded-full" style="background-color:#0287D1" >
                    {{activeRound.number}}
                </span>
            </p>
            <p>
                {{time}}
            </p>
        </div>
        </div>
    <!-- <p class="font-semibold p-1 px-2 rounded-full" v-if="activeRound && activeRound.time">{{time}}</p> -->
</template>

<script>
import store from '../store/index'
import apiManager from "../datas/apiManager";
export default {
    data () {
        return {
            timerLoop: null,
            countDown : 0,
            // countDown : 20,
            backgroundColor : '#0287D1',
            FULL_DASH_ARRAY : 283,
           
            // TIME_LIMIT : 20,
            TIME_LIMIT : this.activeRound.time*60,
            timePassed : 0,
            timerInterval : null,
        }
    },
    props: {
        activeRound :  [String, Number, Object],
        dateNow :  [String, Number, Object],
        // actualTimer : [String, Number, Object]
    },
    computed: {
        score_id () {
            return this.$store.state.account.score_id
        },
        time () {
            var sec_num = parseInt(this.countDown, 10); // don't forget the second param
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}

            // let minutes = (Math.floor(this.countDown/ 60)).toString().padStart(2, '0')
            // let seconds = (this.countDown % 60).toString().padStart(2, '0')

            return hours + ' : ' + minutes + ' : ' + seconds
        },
        remainingPathColor() {
            return this.COLOR_CODES.info.color
        },
        COLOR_CODES() {
            let color = {
                info: {
                    color: "green"
                },
                warning: {
                    color: "orange",
                    threshold: this.WARNING_THRESHOLD
                },
                alert: {
                    color: "red",
                    threshold: this.ALERT_THRESHOLD
                },
            }

            return color
        },
        WARNING_THRESHOLD() {
            return this.TIME_LIMIT * (2/3)
        },
        ALERT_THRESHOLD() {
            return this.TIME_LIMIT/3
        }
    },
    methods: {
        stopTimer() {
            clearInterval(this.timerLoop)
            this.backgroundColor = "#FF644E"
            let data = {
                score_id : this.score_id
            }
            apiManager.submit(data).then(() => {
                // console.log(response);
                store.commit('account/_set', { k: 'is_submit', v: 1 })
            })
        },
        countDownTimer () {
            this.timerLoop = setInterval(() => {
                this.countDown--
                this.setCircleDasharray();
                this.setRemainingPathColor(this.countDown);

                if (this.countDown <= 0) {
                    this.countDown = 0
                    this.stopTimer()
                }
              
                store.commit('account/_set', { k: 'actual_timer', v: this.countDown })
            }, 1000)
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${minutes}:${seconds}`;
        },
        setRemainingPathColor(time) {
            const { alert, warning, info } = this.COLOR_CODES;
            if (time <= alert.threshold) {
                document
                .getElementById("base-timer-path-remaining")
                .classList.remove(warning.color);
                document
                .getElementById("base-timer-path-remaining")
                .classList.add(alert.color);
            } else if (time <= warning.threshold) {
                document
                .getElementById("base-timer-path-remaining")
                .classList.remove(info.color);
                document
                .getElementById("base-timer-path-remaining")
                .classList.add(warning.color);
            } else {
                document
                .getElementById("base-timer-path-remaining")
                .classList.remove(alert.color);
                document
                .getElementById("base-timer-path-remaining")
                .classList.remove(warning.color);
                document
                .getElementById("base-timer-path-remaining")
                .classList.add(info.color);
            }
        },

        calculateTimeFraction() {
            const rawTimeFraction = this.countDown / this.TIME_LIMIT;
            return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
        },

        setCircleDasharray() {
            const circleDasharray = `${(
                this.calculateTimeFraction() * this.FULL_DASH_ARRAY
            ).toFixed(0)} 283`;
            if (document.getElementById("base-timer-path-remaining")) {
                document
                .getElementById("base-timer-path-remaining")
                .setAttribute("stroke-dasharray", circleDasharray);
            }
        }
       
    },
    created () {
        this.countDownTimer()

        //fonction pour convertir en timestamp pour plus de facilité
        // const toTimestamp = (strDate) => {  
        //     const dt = Date.parse(strDate);  
        //     return dt / 1000;  
        // }


        // let tNow = Date.now()
        // let tUpdated = toTimestamp(this.activeRound.updated_at)*1000

        // //Le commencement + temps du round définis = date de fin
        // let limit = tUpdated + (this.activeRound.time * 60 * 1000)

        // //date limite - date de fin = temps restant pour le round
        // let remaning = limit - tNow
        
        // //mon temps restant en secondes
        // this.countDown = Math.round(remaning/1000)


        let tNow = this.dateNow
        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)

        if (this.countDown <= 0 || tNow == null) {
            this.countDown = 0
            this.stopTimer()
        }
    
    },
    beforeUnmount () {
        clearInterval(this.timerLoop)
    },
    watch: {
    dateNow () {
        let tNow = this.dateNow

        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)
        
        if (this.countDown <= 0 || tNow == null) {
            this.countDown = 0
            this.stopTimer()
        }
        
        this.TIME_LIMIT = this.activeRound.time*60
    },
    'activeRound.id'() {
        clearInterval(this.timerLoop)
        this.timerLoop = null
        this.countDown = 0
        this.FULL_DASH_ARRAY = 283
        this.TIME_LIMIT = this.activeRound.time*60
        this.timePassed = 0
        this.timerInterval = null
        

        let tNow = this.dateNow
        //date du round updated (donc depuis activé en timspt)
        // let tUpdated = toTimestamp(this.activeRound.updated_at)
        let tUpdated =this.activeRound.updated_at

        //Le commencement + temps du round définis = date de fin
        let limit = tUpdated + (this.activeRound.time * 60)

        //date limite - date de fin = temps restant pour le round
        let remaning = limit - tNow

        //mon temps restant en secondes
        this.countDown = Math.round(remaning)
        
        this.countDownTimer()

        const { alert, warning, info } = this.COLOR_CODES;
        alert
        warning

        document
        .getElementById("base-timer-path-remaining")
        .classList.remove(info.color);
        document
    }
  }



}
</script>

<style scoped>
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: rgb(209, 207, 207);
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: scaleX(-1) rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #00CB49;
}

.base-timer__path-remaining.orange {
  color: #0287D1;
}

.base-timer__path-remaining.red {
  color:#FF644E;
}

.base-timer__label {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
</style>