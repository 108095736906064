import { createRouter, createWebHistory } from 'vue-router'
import Form from '../views/Form.vue'
import Ranking from '../views/Ranking.vue'
import Admin from '../views/Admin.vue'
import Library from '../views/Library.vue'
import Login from '../views/Login'
import Documents from '../components/Documents'
import store from '../store/index'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
  }, {
    path: '/',
    name: 'Form',
    component: Form
  }, {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking
  }, {
    path: '/xRankingxLivex',
    name: 'Admin',
    component: Admin
  }, {
    path: '/library',
    name: 'Library',
    component: Library
  },{
    path: '/documents',
    name: 'Documents', 
    props: true,
    component: Documents
  }
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Admin' && !store.getters['account/loggedIn']) {
    next({ name: 'Login' })
  }
  else if (to.name === 'Admin' && from.path !== '/') {
    console.log(from)
    next({ name: 'Admin' })
  }
  else if (to.name === 'Login' && store.getters['account/loggedIn']) {
    next({ name: 'Form' })
  }
  else {
    next()
  }
})

export default router
