<template>

	<div v-show="isError ||isErrorForm" class="mt-32">
		<p class="text-xl font-semibold">{{ errorMessage }}</p>
	</div>

	<div v-show="isErrorForm" class="mt-32">
		<p class="text-xl font-semibold">{{ errorMessageForm }}</p>
	</div>

	<div
		v-show="!isError && !isErrorForm"
		class="relative text-left justify-center min-h-screen w-full items-center"
	>

		<div id="sticky-header" class="p-3 w-full p-3 h-content md:h-full" style="position: -webkit-sticky;position: sticky;top: 20px; z-index: 50;background-color:#F9FAFB" >

			<!-- <p style="cursor: pointer" @click="clearSorting()" class="mb-3 underline">Clear sorting</p> -->
			<HeaderForm
				:tagsList="tagsList"
				:allPoints="allPoints"
				:filter="filter"
				:restPool="restPool"
				:pool="pool"
				:actions="actions"
				:isSubmit="isSubmit"
				:isList="isList"
				:reading ="reading"
				:isLived="activeRound != null"
				:coalition="activeRound != null ? activeRound.coalition : null"
				:howMuchNoTag="howMuchNoTag"
				:howMuchNoBet="howMuchNoBet"
				:howMuchBet="howMuchBet"
				@reset-points="resetPoints"
				@addTag="addTag"
				@removeTag="removeTag"
				@filterTag="filterTag"
				@noTagActions="noTagActions"
				@betActions="betActions"
				@resetFilter="resetFilter"
				@filterInput="filterInput"
				@listView="listView"
				@gridView="gridView"
				@noBetActions="noBetActions"
				@bPcoins="bPcoins"
				@aPcoins="aPcoins"
				@aNumber="aNumber"
			/>

			<div :id="'secondTableHeader'" v-if="isList"  :class="'h-14 font-semibold text-black w-full flex justify-center'" style="">
				<div v-if="activeRound && activeRound.coalition == 1" scope="col" class="pt-4"  style="width:7%">
					<div
						class="text-center"
					>
						Coalition
					</div>
				</div>

				<div scope="col" class="my-auto"  style="width:5%">
					<div
					class="text-center"
					style="cursor: pointer"
					@click="sort('index')"
					>
						Rank
					</div>
				</div>

				<div scope="col" class="my-auto"  :style="activeRound && activeRound.coalition == 1 ? 'width:16%' : 'width:23%'">
					<div
					class="text-center"
					style="cursor: pointer"
					@click="sort('tags')"
					>
						Tags
					</div>
				</div>

				<div scope="col" class="my-auto"  style="width:52%">
					<div
					style="cursor: pointer"
					class="text-center"
					@click="sort('data','name')"
					>
						Actions
					</div>
				</div>
				<div scope="col" class="my-auto"  style="width:10%">
					<div
					style="cursor: pointer"
					class="text-center flex justify-center"
					@click="sort('data','value')"
					>
						<p class="mr-1 mt-1">P-Coins</p>
						<img class="w-8" src="/img/pcoins.svg" alt="">
					</div>
				</div>
				<div scope="col" class="my-auto"  style="width:10%">
					<div
					class="text-center"
					style="cursor: pointer"
					@click="sort('index')"
					>
						Info
					</div>
				</div>
			</div>
		</div>


		<form class="form overflow-x-auto" id="form" @submit.prevent="checkForm">
			<table style="border-spacing: 0 5px;border-collapse: separate;" :style="isList ? 'min-width:833px' :''" class="w-full" >
				
				<div
					v-if="actions != undefined"
					:class="isList ? 'bg-white' : 'bg-white flex flex-wrap justify-center lg:justify-between px-4'"
				>
				<template v-for="action in sortedActions" :key="action.data.id">
					<Action
						:isList="isList"
						@changeTag="changeTag"
						@changeAction="changeAction"
						@historyAction="historyAction"
						@exampleAction="exampleAction"
						@addCoalition="addCoalition"
						@removeCoalition="removeCoalition"
						ref="action"
						:action="action"
						:tagsList="tagsList"
						:restPool="restPool"
						:allPoints="allPoints"
						:coalition="activeRound ? activeRound.coalition : null"
						:isLived="activeRound != null"
						:reading="reading"
						:coalitions="coalitions"
						:pool="pool"
						:triggerAction="triggerAction"
					/>
				</template>
				</div>
			</table>

			<div v-if="actions">
				<div  v-if="activeRound == null" class="text-red-600 font-semibold m-3">
					<!--  <p v-if="activeRound != null">You have used too many points! Please redistribute them to validate
					 the form</p> -->
					<p>Loading...</p>
				</div>
			</div>

			<p v-else class="text-red-600 font-semibold mb-3">There is no active form or active session</p>

			<div @click="topFunction" id="myBtn" title="Go to top" style="background-color:#0287D1;display: none;position: fixed;bottom: 20px;right: 30px;z-index: 99;" class="font-semibold text-white p-2 rounded-xl">Top</div>
		</form>
	</div>

	

	<!-- <AlertPopup @close-popup="closePopup" :text="popupMessage" v-show="isPopup" /> -->
	<HistoryPopup ref="historyP" @close-popup-history="closePopupHistory" :isLived="activeRound != null" :data-chart="actionData" v-show="isPopupHistory" :actual-value="actionDataValue" />
	<ExamplePopup @close-popup-example="closePopupExample" :data-chart="actionDataEx" v-show="isPopupExample" :actual-value="actionDataValueEx" />
</template>

<script>
import apiManager from "../datas/apiManager";
import Action from "../components/Action";
// import AlertPopup from "../components/AlertPopup";
import HistoryPopup from "../components/HistoryPopup";
import ExamplePopup from "../components/ExamplePopup";
import HeaderForm from "../components/HeaderForm";
import store from '../store/index'
import { v4 as uuidv4 } from 'uuid';


export default {
	name: "Form",
	components: {
		Action,
		// AlertPopup,
		HistoryPopup,
		HeaderForm,
		ExamplePopup,
	},

	emits: ["filterInput"],
	data: () => ({
		triggerAction: 0,
		reading : false,
		dataForm : [],
		isList : true,
		interval: null,
		actions : [],
		actionDataValue : 0,
		actionData : 0,
		actionDataValueEx : 0,
		actionDataEx : 0,
		pool: 0,
		points: 0,
		filter: "",
		tagsList: [],
		rounds : [],
		currentSort: 'index',
		currentSecondSort: '',
		currentSortDir: "asc",
		isErrorForm : false,
		isError: false,
		errorMessage: "",
		errorMessageForm:"",
		popupMessage: "",
		isPopup: false,
		isPopupHistory : false,
		isPopupExample: false,
		allPoints: false,
		activeRound : null,
		score_id : null,
		coalitions: {
			positive : [],
			negative : [],
		},
		diaries: [],
		last_diary_id : 0,

	}),
	methods: {
		topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},
		addCoalition(action) {
			this.coalitions[action.type].push ({
				id: action.id,
				name : action.name,
			})

			let data = {
				"group_id": this.user.group.id,
				"action": "add",
				"target": "coalition",
				"target_id": action.id,
				"value": action.type,
				"timestamp": Date.now(),
				"uuid": uuidv4(),
			}
			this.createDiary(data)
		},
		removeCoalition(action) {
			this.coalitions[action.type].splice(this.coalitions[action.type].findIndex(oneAction => oneAction.id == action.id),1);

			let data = {
				"group_id": this.user.group.id,
				"action": "remove",
				"target": "coalition",
				"target_id": action.id,
				"value": action.type,
				"timestamp": Date.now(),
				"uuid": uuidv4(),
			}
			this.createDiary(data)
		},
		listView() {
			this.isList = true;

		},
		gridView() {
			this.isList = false;
		},
		closePopup() {
			this.isPopup = false;
		},
		closePopupHistory() {
			this.isPopupHistory = false;
		},
		closePopupExample() {
			this.isPopupExample = false;
		},
		historyAction(actionId, actualValue) {
			this.actionData = actionId
			this.actionDataValue = parseInt(actualValue)
			this.$refs.historyP.getOldData(this.actionData);
			this.isPopupHistory = true
		},
		exampleAction(actionId, actualValue) {
			this.actionDataEx = actionId
			this.actionDataValueEx = parseInt(actualValue)
			this.isPopupExample = true
		},
		addTag() {
			let input = document.getElementById("addTagInput");
			if (input.value !="") {
				let alreadyExist = this.tagsList.find(tag => tag == input.value)
				if (!alreadyExist) {
					this.tagsList.push(input.value);
					let data = {
						"group_id": this.user.group.id,
						"action": "add",
						"target": "tag",
						"target_id": null,
						"value": input.value,
						"timestamp": Date.now(),
						"uuid": uuidv4(),
					}
					this.createDiary(data)
				}
				input.value = "";
			}
		},
		removeTag(tag) {
			var filtered = this.tagsList.filter(function (value) {
				return value != tag;
			});

			let actionsList = this.actions;
			for (const key in actionsList) {
				if (actionsList[key].data.tags) {
					let action = actionsList[key].data.tags.filter(function (value) {
						return value != tag;
					});
					actionsList[key].data.tags = action;
				}
			}
			this.actions = actionsList;
			this.tagsList = filtered;
			this.filter = "";

			let data = {
				"group_id": this.user.group.id,
				"action": "remove",
				"target": "tag",
				"target_id": null,
				"value": tag,
				"timestamp": Date.now(),
				"uuid": uuidv4(),
			}
			this.createDiary(data)
		},
		filterTag(tag) {
			this.filter = { tag: true, value: tag };
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		noTagActions() {
			this.filter = { tag: true, value: '' };
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		noBetActions() {
			this.filter = { bet: 'noBet', value: 0 };
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		betActions() {
			this.filter = { bet: 'bet', value: 0 };
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		resetFilter() {
			this.filter = 'resetFilter'
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		bPcoins() {
			this.filter = 'resetFilter'
			this.currentSortDir =  'desc'
			this.currentSort = 'data'
			this.currentSecondSort ='value'
		},
		aPcoins() {
			this.filter = 'resetFilter'
			this.currentSortDir =  'asc'
			this.currentSort = 'data'
			this.currentSecondSort ='value'
		},
		aNumber() {
			this.filter = 'resetFilter'
			this.currentSortDir =  'asc'
			this.currentSort = 'index'
		},
		filterInput(filter) {
			this.filter = filter;
		},
		resetPoints (isDiary) {
			let resetActions = this.actions
			resetActions.forEach(action => {
				action.data.value = 0
			});
			this.actions = resetActions
			this.points = 0
			this.$refs.action.forEach(actionElem => {
				actionElem.resetPoints()
			});
			
			if (!isDiary) {
				let data = {
					"group_id": this.user.group.id,
					"action": "reset",
					"target": "answer",
					"target_id": null,
					"value": 0,
					"timestamp": Date.now(),
					"uuid": uuidv4(),
				}
				this.createDiary(data)
			}
			
		},
		sort(s,z) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
			}

			this.currentSecondSort = z ? z : ''
			this.currentSort = s;
		},
		changeTag(value) {

			// this.tags[value.action] = value.tag;
			let activeAction = this.actions.find(
				(action) => action.data.id == value.action
			);
			activeAction.data.tags = value.tag;
			
			let data = {
				"group_id": this.user.group.id,
				"action": 'update',
				"target": "answerTag",
				"target_id": value.action,
				"value": value.tag,
				"timestamp": Date.now(),
				"uuid": uuidv4(),
			}
			this.createDiary(data)
		},
		changeAction(value, isDebounce) {
			//modifier la bonne actions correspondante = prochainement ajouter la fameuse ligne au journal si +1 +n -1 -n ....

			if (isDebounce) {
				let data = {
					"group_id": this.user.group.id,
					// "action":  value.action,
					"action":  'update',
					"target": "answer",
					"target_id": value.target_id,
					"value": value.answer,
					"timestamp": Date.now(),
					"uuid": uuidv4(),
				}
				this.createDiary(data)
			} else {
				let modifiedAction = this.actions.find(action => action.data.id == value.target_id)
				modifiedAction.data.value = value.answer
				this.calculRestPool()
			}
		
		},
		calculRestPool(){
			let points = 0;
			this.actions.forEach(action => {
				points += action.data.value != null ? parseInt(action.data.value) : 0;
			});
			this.points = points;
		},
		createDiary (data) {
			if (this.restPool >= 0 && this.isSubmit == 0) {
				apiManager.createDiary(data).then(() => {
				}).catch(() => {
				})
			}
		},
		checkForm (e) {
			if (e) {
				e.preventDefault();
			}
		},
		async getLastDiaries() {
			//diaries
			if (this.loadingDataDiary) {
				console.log('waiting for refresh diaries')
				return;
			}
			if (this.reading && !this.loadingDataDiary) {
				this.changeLoadingDataDiary(true)
				let dataDiaries = {
					score_id : this.$store.state.account.score_id,
					last_diary_id : this.last_diary_id
				}
				let diaries = await apiManager.getLastDiaries(dataDiaries)

				//ne pas appliqué ceux qui ont déjà été appliqué < last_diary
				diaries.data.forEach(diary => {
					if (diary.id > this.last_diary_id) {
						this.updateData(diary)
						this.last_diary_id = diary.id
					}
				});
				this.changeLoadingDataDiary(false)
			}
		},
		async updateData(storeDiary) {
			//ensuite on va appliqué les changements
			if (storeDiary.target == 'answer') {
				if (storeDiary.action == 'reset') {
					this.resetPoints(true)
				}  
				else {
					let targetAnswer = this.actions.find(action => action.data.id == storeDiary.target_id)
					if (targetAnswer) {
						targetAnswer.data.value = parseInt(storeDiary.value)
					}
				}
			
			} else if (storeDiary.target == 'answerTag') {
				let targetAnswer = this.actions.find(action => action.data.id == storeDiary.target_id)
				if (targetAnswer) {
					targetAnswer.data.tags = Object.values(JSON.parse(storeDiary.value))
				}
			} else if (storeDiary.target == 'tag') {
				let tagKey = this.tagsList.findIndex(tag => storeDiary.value == tag);

				if (storeDiary.action == 'add' && tagKey === -1) {
					//ajouter de la liste des tags
					this.tagsList.push(storeDiary.value);
				}

				if (storeDiary.action == 'remove' && (tagKey || tagKey === 0)) {
					this.tagsList.splice(tagKey, 1);
				}
		
			} else if (storeDiary.target == 'coalition') {
				let targetAnswer = this.actions.find(action => action.data.id == storeDiary.target_id)
				if (targetAnswer) {
					let tagKey = this.coalitions[storeDiary.value].findIndex(coalition => coalition.id == storeDiary.target_id);
					if (storeDiary.action == 'add' && tagKey === -1) {
						//ajouter de la liste des tags
						this.coalitions[storeDiary.value].push({
							id : targetAnswer.data.id,
							name : targetAnswer.data.name,
						});
					}

					if (storeDiary.action == 'remove' && (tagKey || tagKey === 0)) {
						this.coalitions[storeDiary.value].splice(tagKey, 1);
					}
				}
			}
			this.triggerAction++
			this.calculRestPool()
		},
		async loadData(firstLoading) {
			//! todo revoir la boucle load data
			//todo vérifier les erreurs
			if (this.loadingData) {
				console.log('waiting for refresh')
				return;
			}


			this.changeLoadingData(true)
			if(this.user) {
				//status de l'utilisateur
				this.reading = (this.user.status == 'view' || this.user.status=='reading') ? true : false
				store.commit('account/_set', { k: 'reading', v: this.reading })

				//recup malus (si payé library)
				let data = {
					group_id : this.user.group.id
				};
				apiManager.getLoggedUser(data).then((response) => {
					this.$store.state.account.data.group.malus = response.data.malus
				});

				if (firstLoading) {
					//refresh du score à chaque f5
					//todo passer un paramètre pour calculer le score d'une équipe ? à priori ils rafraichient pas toutes les  2 sec mais bon
					let dataCalcul = {
						group_id : this.user.group.id,
						round_id : null
					}
					let score = await apiManager.calculate(dataCalcul)
					if (!score.data) {
						this.isError = true;
						this.errorMessage = score.response ? score.response.data.message : "The game has not started yet. Please wait until the organisers activate the session.";
						this.changeLoadingData(false)
						return
					}
					this.last_diary_id = score.data.last_diary_id
				}
			}

			let [activeRound, formResult, scoreResult] = await Promise.all([
				apiManager.getActiveRound(),
				apiManager.getForm(),
				apiManager.getScore(this.user.group.id)
			]);

			//pas form
			if (!formResult.data) {
				this.isError = true;
				this.errorMessage = formResult.response.data.message ? formResult.response.data.message : "Error";
				this.changeLoadingData(false)
				return
			}

			//si abort group pas dans la session
			if (!scoreResult.data) {
				this.isError = true;
				this.errorMessage = scoreResult.response.data.message ? scoreResult.response.data.message : "Error";
				this.changeLoadingData(false)
				return
			}

			//si session active
			if (!activeRound.data) {
				this.isError = true;
				this.errorMessage = activeRound.response.data.message ? activeRound.response.data.message : "Error";
				this.changeLoadingData(false)
				return
			}

			if (formResult.data != '') {
				let newData = [];
				let parsingData = JSON.parse(formResult.data.data.actions);
				this.pool = formResult.data.data.pool;
				//je vais chercher les actions liées à ce formulaire (importées)
				//je les ranges dans un objet qui me permet de bien les afficher
				parsingData.forEach((data) => {
					newData.push({
						id: data.id,
						name: data.name,
						tags: "",
						value: null,
						example: data.example
					});
				});
				this.dataForm = newData
				this.isErrorForm = false;
			} else {
				this.isError = true;
				this.errorMessage = "There is no active form";
			}

			if (scoreResult.data != '') {
				//Je vais chercher s'il y a déjà des scores existants
				let session = scoreResult.data.session
				this.activeRound = session.activeRound
				
				store.commit('account/_set', { k: 'rest_pool', v: this.pool - this.points - this.user.group.malus })
				store.commit('account/_set', { k: 'library_cost', v: session.activeRound != null ? session.activeRound.library_cost : null })
				store.commit('account/_set', { k: 'active_round', v: session.activeRound })
				store.commit('account/_set', { k: 'date_now', v: session.dateNow })

				//si score vide (tout nouveau pas encore joué) en prend les data form sinon data score
				//normalement n'exite plus on est obligé d'avoir un score mais je laisse au cas où
				if (scoreResult.data.score == null || (scoreResult.data.score && scoreResult.data.score.answers == null)) {

					// ne pax actualiser les data si ce n'est pas f5
					if (!firstLoading) {
						this.changeLoadingData(false)
						return false;
					}
					
					let actionsData = []

					for (let index = 0; index < this.dataForm.length; index++) {
						actionsData.push({
							index : index,
							data : this.dataForm[index]
						})
					}

					let answerData = []

					for (let index = 0; index < this.dataForm.length; index++) {
						answerData.push({
							index : index,
							data : this.dataForm[index],
							tags : {},
							value : 0,
							example :null,
						})
					}
					this.actions = actionsData
					
					this.coalitions =  {
						positive : [],
						negative : [],
					}

					this.points = 0
					this.isError = false;
					store.commit('account/_set', { k: 'is_submit', v: 0 })

				} else {
					let score = scoreResult.data.score
					this.isError = false;
					let parsingDataNew = [];

					//Je range les réponses et les tags
					parsingDataNew = Object.values(JSON.parse(score.answers));

					this.score_id = score.id;

					if (session.activeRound && score.round_id == session.activeRound.id ) {
						store.commit('account/_set', { k: 'is_submit', v: score.is_submit })
					} else {
						store.commit('account/_set', { k: 'is_submit', v: 0 })
					}

					store.commit('account/_set', { k: 'score_id', v: score.id })

					if (!firstLoading && this.actions.length>0) {
						this.changeLoadingData(false)
						return false;
					}

					if(score.coalition && Object.keys(JSON.parse(score.coalition)).length != 0) {
						this.coalitions.positive = Object.values(JSON.parse(score.coalition).positive)
						this.coalitions.negative = Object.values(JSON.parse(score.coalition).negative)
					}

					this.tagsList = Object.values(JSON.parse(score.tags));

					//je vais chercher les réponses (avec les tags associés et leur valeur)
					let actionsData = []
					for (const key in parsingDataNew) {
						actionsData.push(parsingDataNew[key]);
					}
					for (const key in actionsData) {
						if (actionsData[key].data.tags) {
							actionsData[key].data.tags = Object.values(
								actionsData[key].data.tags
							);
						}
					}

					this.actions = actionsData
					this.calculRestPool()

				}

			}
			this.changeLoadingData(false)

		},
		changeLoadingData(value) {
			this.$store.commit('_set', { k: 'loadingData', v: value })
		},
		changeLoadingDataDiary(value) {
			this.$store.commit('_set', { k: 'loadingDataDiary', v: value })
		},
	},
	computed: {
		isSubmit() {
			return this.$store.state.account.is_submit
		},
		loadingData() {
			return this.$store.state.loadingData;
		},
		loadingDataDiary() {
			return this.$store.state.loadingDataDiary;
		},
		howMuchNoBet() {
			let count = 0
			this.actions.forEach(action => {
				if (action.data.value == 0 || action.data.value == null) {
					count ++
				}
			});
			return count
		},
		howMuchNoTag() {
			let count = 0
			this.actions.forEach(action => {
				if (action.data.tags && action.data.tags.length > 0) {
					count ++
				}
			});
			return this.actions.length - count
		},
		howMuchBet() {
			let count = 0
			this.actions.forEach(action => {
				if (action.data.value != 0 && action.data.value != null) {
					count ++
				}
			});
			return count
		},
		user() {
			return this.$store.state.account.data;
		},
		allRounds() {
			return this.$store.state.account.rounds;
		},
		restPool() {
			// return this.pool - this.points - this.user.group.malus;
			if (this.user) {
				// store.commit('account/_set', { k: 'rest_pool', v: this.pool - this.points - this.user.group.malus })
				return this.pool - this.points - this.user.group.malus;
			}
			
			return this.pool - this.points
		},
		filteredActions() {
			this.triggerAction
			if (this.filter == 'resetFilter') {
				return this.actions
			} else if (this.filter.tag) {
				let actionslist = this.actions;
				return actionslist.filter((c) => {
					if (this.filter.value == "") return !c.data.tags;
					if (c.data.tags) {
						return c.data.tags.includes(this.filter.value);
					}
				});
			} else if (this.filter.bet && this.filter.bet == 'bet') {
				let actionslist = this.actions;
				return actionslist.filter((c) => {
					// if (this.filter.value == "") return true;
					if (c.data.value) {
						return !c.data.value == 0 || !c.data.value == null
					}
				});
			} else if (this.filter.bet && this.filter.bet == 'noBet') {
				let actionslist = this.actions;
				return actionslist.filter((c) => {
					// if (this.filter.value == "") return true;
					// if (c.data.value) {
					return c.data.value == 0 || c.data.value == null;
					// }
				});
			} else {
				let actionslist = this.actions;
				return actionslist.filter((c) => {
					if (this.filter == "") return true;
					if (c.data.tags) {
						return (
								c.data.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
								c.data.tags.includes(this.filter)
						);
					} else {
						return c.data.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
					}

					/*   || c.tag.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0  */
				});
			}
		},
		sortedActions() {
			this.triggerAction
			let filteredActionsList = this.filteredActions;

			if (this.currentSort === "tags") {
				return filteredActionsList.sort((a, b) => {

					let modifier = 1;
					if (this.currentSortDir === "desc") modifier = -1;

					if (a['data'][this.currentSort] === null) {
						return 1* modifier;
					}
					if (b['data'][this.currentSort] === null) {
						return -1* modifier;
					}

					// if (a['data'][this.currentSort] != null && b['data'][this.currentSort] != null) {
					if (a['data'][this.currentSort].length < b['data'][this.currentSort].length ) {
						return -1 * modifier
					}
					if (a['data'][this.currentSort].length > b['data'][this.currentSort].length) {
						return 1 * modifier
					}
					// }
					return 0;

				})
			} else {
				return filteredActionsList.sort((a, b) => {
					let modifier = 1;
					if (this.currentSecondSort != '') {
						if (this.currentSortDir === "desc") modifier = -1;

						//pb ici de value non int
						if (this.currentSecondSort == 'value') {
							let aValue = a[this.currentSort][this.currentSecondSort] != null ? parseInt(a[this.currentSort][this.currentSecondSort]) : 0
							let bValue = b[this.currentSort][this.currentSecondSort] != null ? parseInt(b[this.currentSort][this.currentSecondSort]) : 0

							if (aValue < bValue) {
								return -1 * modifier
							}
							if (aValue > bValue) {
								return 1 * modifier
							}
						} else {
							if (a[this.currentSort][this.currentSecondSort] < b[this.currentSort][this.currentSecondSort]) {
								return -1 * modifier
							}
							if (a[this.currentSort][this.currentSecondSort] > b[this.currentSort][this.currentSecondSort]) {
								return 1 * modifier
							}
						}

					}

					else {
						if (this.currentSortDir === "desc") modifier = -1;
						if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
						if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
						return 0;
					}

				})
			}


		},

	},
	mounted() {
		var mybutton = document.getElementById("myBtn");
		var stickyHeader = document.getElementById("sticky-header");
		var timer = document.getElementById("myTimer");

		// When the user scrolls down 20px from the top of the document, show the button
		window.onscroll = function() {scrollFunction()};

		function scrollFunction() {
			if (document.body.scrollTop > 180 || document.documentElement.scrollTop > 180) {
				mybutton.style.display = "block";
				timer.style.display = "block";
				stickyHeader.classList.add('shadow-lg')
				// secondTableHeader.style.display = "";


			} else {
				mybutton.style.display = "none";
				timer.style.display = "none";
				stickyHeader.classList.remove('shadow-lg')
				// secondTableHeader.style.display = "none";
			}
		}
		// When the user clicks on the button, scroll to the top of the document
	},
	created() {
		this.changeLoadingData(false)
		this.changeLoadingDataDiary(false)
		this.loadData(true)

		this.interval = setInterval(() => {
			this.getLastDiaries()
		}, 7000)

		//je suis obligé de bouclé ici pour avoir l'actualisation des rounds : si c finis ou pas
		this.interval = setInterval(() => {
			this.loadData()
		}, 9000)

	},
	beforeUnmount () {
		clearInterval(this.interval)

	},
	watch: {
		restPool (val) {
			if (val < 0) {
				// this.isPopup = true;
				this.allPoints = true;
				// this.popupMessage =
				//   "You have used too many points! Please redistribute them to validate the form";
			} else {
				this.allPoints = false;
				// this.isPopup = false;
			}
		},
		filter() {
			this.currentPage = 1;
		},
	}

};
</script>
