<template>
  <router-link to="/library">
    <h1 class="mb-6 text-xl font-bold">Library</h1>
  </router-link>
  <div v-if="documents">
      <div class="flex justify-center items-center">
        <i class="fa fa-folder-o mr-2" aria-hidden="true"></i>
        <h2 class="underline">Categories/<span class="font-semibold">{{category}}</span></h2>
      </div>
    
      <div v-if="JSON.parse(documents).data.length > 0" class="flex justify-center items-center flex-wrap">
        <div v-for="document in JSON.parse(documents).data" :key="document.id" class="m-10 w-56 rounded-md shadow-2xl shadow-black" :style="'height:23rem;background-color:' + color">
          <a :href="document.link" target="_blank" class="text-white font-semibold">
            <div v-if="document.activated" class="text-center m-auto h-full justify-center items-center">
              <div class="w-full h-44 overflow-hidden"> 
                <img v-if="document.media !='/'" :src="baseURL + document.media" class="mr-2 w-full h-full" alt="" style="object-fit: cover;">
                <p class="mr-2 w-full mt-16" v-else>No Picture</p>
              </div>
              <p class="m-3">{{document.name}}</p>
            </div>
          </a>
        </div>
      </div>
      <div v-else class="flex justify-center items-center flex-wrap mt-6">
        No Documents
      </div>
      <router-link to="/library">
        <div>
          <i class="fa fa-backward" aria-hidden="true"></i> 
          <span class="underline ml-2">Go back</span>
        </div>
      </router-link>
  </div>
</template>

<script>

export default {
  name: "Documents",
  components: {

  },
  props: {
    documents: String,
    category: String,
    color: String,
  },
  data: () => ({
    categories : [],
   
  }),
  methods: {
  
  },

  computed: {
    user() {
      return this.$store.state.account.data;
    },
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return '';
      }
    },
   
  },
  created() {
    
  },
  watch: {
    
  }

};
</script>

<style scoped>
</style>
