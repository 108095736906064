export default {
  namespaced: true,
  state: () => ({
    data: null,
    malus: null,
    library_cost: null,
    pay: null,
    score_id: null,
    active_round: null,
    rest_pool: null,
    is_submit : 0,
    actions : [],
    rounds : [],
    last_diary_id : null,
    date_now: null,
    reading: false,
    diaries : [],
  }),
  getters: {
    loggedIn: state => {
      return state.data != null
    },
  },
  mutations: {
    _set (state, data) {
      state[data.k] = data.v
    },
    _delete (state, data) {
      state[data] = null
    }
  },
  actions: {
    _deleteAll (context) {
      for (let state in context.state) {
        context.commit('_delete', state)
      }
    }
  }
}
