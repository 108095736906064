<template>
  <div
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
  >
    <div
      class="absolute bg-black opacity-20 inset-0 z-0"
      style="z-index: 3"
      @click="closePopup()"
    ></div>
      <div
        style="width: 40rem;z-index: 4"
        class="w-full max-w-6xl relative mx-auto my-auto rounded-xl shadow-lg bg-white p-12"
      >
        <div class="">
          <div class="text-center flex-auto justify-center">
            {{text}}
          </div>

          <!--footer-->
          <div class="mt-6 text-center space-x-4 md:block">
            <a
              class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-semibold tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
              id="return"
              style="cursor: pointer;"
              @click="closePopup()"
            >
              Back
            </a>
            <a
              class="mb-2 md:mb-0 px-5 py-2 text-sm shadow-sm font-semibold tracking-wider text-white rounded-full hover:shadow-lg"
              id="reset"
              onmouseover="this.style.background='#049A3A'"
              onmouseout="this.style.background='#00cb49'"
              style="background-color: #00cb49;cursor: pointer;"
              @click="resetPoints()"
            >
              Yes
            </a>
          </div>
        </div>
      </div>
  </div>
</template>


<script>
export default {
  components: {},
  emits: ["close-popup", "reset-points"],
  data: () => ({}),
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    closePopup() {
       this.$emit("close-popup");
    },
    resetPoints() {
       this.$emit("reset-points");
       this.$emit("close-popup");
    },
  },
  created() {},
  computed: {
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
  },
};
</script>
