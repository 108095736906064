<template>
  <div
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
    style="z-index:80"
  >
    <div
      class="absolute bg-black opacity-20 inset-0 z-0"
      style="z-index:70"
      @click="closePopup()"
    ></div>
      <div>
        <img src="/img/popup.svg" style="width:27rem;transform: translate(0,7rem);z-index:90;position: relative;" alt="" class="m-auto">
        <div
          style="width: 40rem;height:20rem;background-color:#0287D1;z-index:80;position: relative;"
          class="w-full max-w-6xl relative mx-auto my-auto rounded-xl shadow-lg p-12 "
        >
          <div v-if="reading != true" class="h-full pt-20">
            <div v-if="!is_negative">
              <div class="text-center flex flex-auto justify-center">
                <p class="text-white text-2xl font-semibold">Do you want to pay</p>
                <p class="ml-1 text-yellow-300  text-2xl font-semibold">{{library_cost}} coins </p>
                <img src="/img/pcoins.svg" style="width:1.9rem" alt="" class="ml-1">
              </div>
              <div class="text-center flex flex-auto justify-center">
                <p class="ml-1 text-white  text-2xl font-semibold">to access the library ?</p>
              </div>
            </div>
            <div v-else class="text-center flex flex-auto justify-center">
              <p class="text-white text-2xl font-semibold">Not enough p-coins !</p>
            </div>

            <!--footer-->
            <div class="mt-8 text-center space-x-4 md:block">
              <a
                class="text-xl mb-2 md:mb-0 px-8 py-3 text-sm shadow-sm font-semibold tracking-wider text-white rounded-full hover:shadow-lg hover:bg-gray-100"
                id="return"
                style="cursor: pointer;background-color:#FF644E"
                @click="closePopup()"
              >
                {{(!is_negative ? 'Not yet' : 'Close')}}
              </a>
              <router-link
                to="/library"
                v-if="!is_negative"
                @click="closePopup()"
                style="background-color:#00CB49"
                class="text-xl mb-2 md:mb-0 px-8 py-3 text-sm shadow-sm font-semibold tracking-wider rounded-full hover:shadow-lg hover:bg-gray-100"
                replace>Start Using
              </router-link>
            
            </div>
          </div>
          <div v-else class="h-full pt-20">
            <div class="text-center flex flex-auto justify-center">
                <p class="text-white text-2xl font-semibold">Your team have no access to the library !</p>
            </div>
          </div>
          
        </div>
      </div>
  </div>
</template>


<script>
// import apiManager from "../datas/apiManager"; 
export default {
  components: {},
  emits: ["close-popup"],
  data: () => ({}),
  props: {
    library_cost : [Number, String],
    malus : [Number, String],
    reading : [Boolean],
    rest_pool : [Number, String],
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    // closePopupAndPay() {
    //   if(this.malus == 0 || this.malus == null) {


    //     let data = {
    //       malus: this.malus,
    //       library_cost: this.library_cost,
    //       score_id: this.score_id,
    //       team_id : this.user.id
    //     };

    //     apiManager.payLibrary(data).then((response) => {
    //       console.log(response);
    //     });
    //   }
     
    //   this.$emit("close-popup");
    // },
  },
  created() {
    // if (this.library_cost == 0) {
    //   this.$router.push('/library')
    // }
  },
  computed: {
    user() {
      return this.$store.state.account.data;
    },
    score_id () {
      return this.$store.state.account.score_id
    },
    is_negative() {

      if(this.rest_pool - this.library_cost < 0) {
        return true
      }

      return false
    },
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
   
  },
};
</script>
