<template>
  <div
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
    style="z-index:80;"
  >
    <div
    style="z-index:80;"
      class="absolute bg-black opacity-20 inset-0 z-0"
      @click="closePopup()"
    ></div>
      <div
        style="width: 60rem;z-index:90;"
        class="w-full max-w-6xl relative mx-auto my-auto rounded-xl shadow-lg bg-white p-6"
      >
        <div class="mb-6 text-right">
          <a
            class="mb-2 p-4 py-3 text-lg text-white shadow-sm font-semibold rounded-full hover:shadow-lg hover:bg-gray-100"
            id="return"
            style="cursor: pointer;background-color:#FF644E;width:10rem;height:10rem"
            @click="closePopup()"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        
        </div>
     
        <p class="mb-4 mt-4 font-semibold text-2xl">Betting History</p>
       
        <div class="m-6">
          <HistoryChart v-if="!isZero" :data-chart="oldValues"/>
          <p v-else>No bet for this action</p>
        </div>
      </div>
  </div>
</template>


<script>
import apiManager from "../datas/apiManager"; 
import HistoryChart from './HistoryChart.vue'

export default {
  components: {  
    HistoryChart
  },
  emits: ["close-popup-history"],
  data: () => ({
    oldValues : [],
    examples : '',
  }),
  props: {
    dataChart: {
      type: Number,
    },
    actualValue : {
      type: Number,
    },
    isLived : {
      type : Boolean
    }
  },
  methods: {
    closePopup() {
      this.$emit("close-popup-history");
    },
    getOldData(val){
      let oldActionValues = []
      apiManager.getHistory(this.user.group.id)
      // .getRanking()
      .then((response) => {
        let endedRounds = response.data.endedRounds

        let tableRounds = []
        for (const key in endedRounds) {
          tableRounds.push(endedRounds[key])
        }

        tableRounds.sort(function compare(a, b) {
          if (a.number < b.number)
            return -1;
          if (a.number > b.number )
            return 1;
          return 0;
        });

        endedRounds = tableRounds

        let prevScore = 0
        endedRounds.forEach(endedRound => {
          let scoreForRound = false
          let actions = JSON.parse(endedRound.answers)
          for (const key in actions) {
            if (actions[key].data.id == val) {
              oldActionValues.push(actions[key].data.value == null ? 0 : actions[key].data.value)
              scoreForRound = true
              prevScore = actions[key].data.value == null ? 0 : actions[key].data.value
              return
            }
          }

          if (!scoreForRound) {
            oldActionValues.push(prevScore)
          }
        });
        let oldData = oldActionValues

        if (this.isLived) {
          oldData.push(this.actualValue == null ? 0 : this.actualValue)
        }
        
        this.oldValues = oldData
      })
    }
  },
  created() {
    
  },
  computed: {
    isZero () {
      let sum = 0;

      for (let i = 0; i < this.oldValues.length; i++) {
        sum += (this.oldValues[i] == null || isNaN(this.oldValues[i]) ? 0 : this.oldValues[i]);
      }
      return sum == 0 ? true : false
    },
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
    user() {
      return this.$store.state.account.data;
    },
  },
  watch: {
    dataChart(val) {
      this.getOldData(val);
    },
    // actualValue() {
    //   console.log(this.actualValue)
    //   this.getOldData(this.dataChart);
    // },
  }
};
</script>
