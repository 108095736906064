<template>
  <div
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
    style="z-index:80;"
  >
    <div
    style="z-index:80;"
      class="absolute bg-black opacity-20 inset-0 z-0"
      @click="closePopup()"
    ></div>
      <div
        style="width: 60rem;z-index:90;"
        class="w-full max-w-6xl relative mx-auto my-auto rounded-xl shadow-lg bg-white p-6"
      >
        <div class="mb-6 text-right">
          <a
            class="mb-2 p-4 py-3 text-lg text-white shadow-sm font-semibold rounded-full hover:shadow-lg hover:bg-gray-100"
            id="return"
            style="cursor: pointer;background-color:#FF644E;width:10rem;height:10rem"
            @click="closePopup()"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        
        </div>

        <div class="m-6">

          <p class="font-semibold mb-6 text-2xl">Examples</p>

          <div v-for="(example) in examples" :key="example">
            <li v-if="example !=' '" class="list-disc">{{example}}</li>
          </div>
      
          <!-- <vue-collapsible-panel-group  base-color="#00070A">
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    <p class="font-semibold">Examples</p>
                </template>
                <template #content>
                  <div v-for="(example) in examples" :key="example">
                    <p class="text-left">{{example}}</p>
                  </div>
                </template>
            </vue-collapsible-panel>
          
          </vue-collapsible-panel-group> -->

        </div>

       

      </div>
  </div>
</template>


<script>
import apiManager from "../datas/apiManager"; 
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import {
  // VueCollapsiblePanelGroup,
  // VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel'

export default {
  components: {  
    // VueCollapsiblePanelGroup,
    // VueCollapsiblePanel,
  },
  emits: ["close-popup-example"],
  data: () => ({
    oldValues : [],
    examples : '',
  }),
  props: {
    dataChart: {
      type: Number,
    },
    actualValue : {
      type: Number,
    }
  },
  methods: {
    closePopup() {
      this.$emit("close-popup-example");
    },
    getOldData(val){
      apiManager.getForm()
      .then((response) => {
        let parsingData = JSON.parse(response.data.data.actions);
        // console.log(response.data.data.actions)
        this.pool = response.data.data.pool;

        parsingData.forEach((data) => {
          if (data.id == val && data.example) {
            this.examples = data.example.split('§');
          }
          if (data.id == val && !data.example){
            this.examples = ['No Examples']
          }
      
        });
     
      })
      
    }
  },
  created() {
    
  },
  computed: {
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
    user() {
      return this.$store.state.account.data;
    },
  },
   watch: {
    dataChart(val) {
      this.getOldData(val);
    },
    actualValue() {
      this.getOldData(this.dataChart);
    },
  }
};
</script>
