<template>
  <div v-if="this.user == null" id="login" class="w-screen h-screen flex justify-between">
    <div class="hidden md:block gauche text-left w-2/3 h-full">
      <img class="img2 text-left w-full h-full" src="/img/login2.svg" alt="" style="object-fit: cover;">
      <img class="img1 text-left w-full h-full" src="/img/login1.svg" alt="" style="object-fit: cover;">
      <!-- <div class="img1 text-left w-full h-full" style="background-image: url(/img/login1.svg);background-size: cover;background-repeat: no-repeat;">
      </div>
      <div class="img2 text-left w-full h-full" style="background-image: url(/img/login1.svg);background-size: cover;background-repeat: no-repeat;">
      </div> -->
    </div>
    <div class="right m-auto w-full md:w-1/3 h-full relative" style="background-color:#0287D1;">
      <form class="absolute w-full m-auto" style="top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
        <div class="flex flex-col align-center w-full gap-2 px-6 py-4 overflow-hidden">
          <div ref="error" class="text-center text-red-500 font-bold"></div>
          <input ref="loginInput" class="mb-3 rounded-lg block mt-1 m-auto w-8/12 h-16 p-1 text-center text-white"
                style="background-color:#4db3ea;"
                type="text"
                name="login"
                id="login"
                v-model="input.login"
                :rules="'required'"
                placeholder="Your Login"
                v-on:keyup.enter="login()"/>

          <input ref="passwordInput"
                class="mb-3 rounded-lg block mt-1  w-8/12 m-auto h-16 p-1 text-center text-white"
                style="background-color:#4db3ea;"
                type="password"
                name="password"
                id="password"
                placeholder="Your Password"
                v-model="input.password"
                :rules="'required'"
                v-on:keyup.enter="login()"/>

          <p class="font-semibold" v-if="message!=''" style="color:rgb(255, 100, 78)">{{message}}</p>

          <button class="w-52 mt-6 inline-flex items-center px-4 py-2  border border-transparent rounded-full font-semibold text-xs text-white uppercase tracking-widest  disabled:opacity-25 transition ease-in-out duration-150 m-auto"
          style="background-color:#00CB49;" onmouseover="this.style.background='#13b24b';" onmouseout="this.style.background='#00CB49';"
                  type="button"
                  @click="login()">
                  <p class="text-center w-full text-lg">START PLAYING</p>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import taskManager from '../datas/taskManager'
import router from '../router'

export default {
  components: {

  },
  name: 'Login',
  data () {
    return {
      input: {
        login: '',
        password: ''
      }
    }
  },
  props: {
    message: String,
  },
  computed : {
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
    user () {
      return this.$store.state.account.data
    }
  
  },
  methods: {
    login () {
      if (this.input.login != '' && this.input.password != '') {
        taskManager.login(this.input.login, this.input.password, this.sucessLogin, this.failedLogin)
      }
      else {
        this.displayError('All fields must be completed.')
      }
    },
    sucessLogin () {
      this.$refs.passwordInput.value = ''
      router.push({ name: 'Form' })
    },
    failedLogin () {
      this.$refs.passwordInput.value = ''
      this.displayError('These identifiers are invalid')
    },
    displayError (message) {
      this.$refs.error.innerText = message
    }
  },
  beforeCreate() {
    document.body.className = 'login';
  },
}
</script>

<style scoped>
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    white;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    white;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    white;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color:    white;
}

::placeholder { /* Most modern browsers support this now. */
  color:    white;
}

@keyframes cf3FadeInOut {
  0% {
    opacity:1;
  }
  15% {
    opacity:1;
  }
  85% {
    opacity:0;
  }
  100% {
    opacity:0;
  }
}

.gauche {
  position:relative;
}

.gauche img {
  position:absolute;
}


.gauche img.img1 {
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 7s;
  animation-direction: alternate;
}

 
</style>
