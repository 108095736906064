<template>
  <div class="flex w-full">
    <div class="h-full w-32 mr-3">
      <div class="flex justify-center" style="margin-top:6px">
        <img :src="'/img/list' + (isList ? 'b' : 'g') + '.svg'" alt="" class="w-10 mr-3" style="cursor: pointer" @click="listView()">
        <img :src="'/img/grid' + (!isList ? 'b' : 'g') + '.svg'" alt="" class="w-10" style="cursor: pointer" @click="gridView()">
      </div>
    </div>
    <div class="w-full">
      <div class="lg:flex lg:justify-between mb-2 lg:mb-0 w-full">
        <div class="md:flex h-full md:h-10 mb-3">

          <div class="flex border h-12 p-1 lg:mr-4 rounded-xl bg-white">
            <input
              type="text"
              class="h-10 my-auto mb-4 p-2 lg:mr-3 w-10/12"
              placeholder="Add new tag..."
              id="addTagInput"
            />
            <div
              @click="addTag()"
              v-if="isSubmit == 0 && reading != true && isLived"
              style="cursor: pointer"
              class="my-auto bg-white w-2/12"
            >
              <img style="vertical-align: middle;"
                class="h-7 w-7 text-center text-white text-4xl" src="/img/plus.svg" alt="">
            </div>
          </div>
          <div class="flex border h-12 p-1 rounded-xl bg-white">
            <input
              type="search"
              v-model="filterHeader"
              class="h-10 my-auto mb-4 p-2 lg:mr-3"
              placeholder="Search keyword, #tag..."
              @input="filterInput($event,this)"
            />
            <i class="fa fa-search fa-lg mt-2" aria-hidden="true"></i>
          </div>
         

          <div class="md:ml-4" style="z-index: 30">
            <Menu as="div" class="relative inline-block text-left rounded-xl">
              <div>
                <MenuButton
                  class=" h-12 flex items-center font-medium border border-gray-300 px-10 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-100 rounded-xl"
                  style="padding: 0 0 0 10px; width: 13rem;"
                >
                  {{activefilter}}
                  <ChevronDownIcon class="ml-28 h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1">
                    <MenuItem>
                      <button
                        type="submit"
                        :class="'h-8 w-full mr-2 hover:bg-gray-200'"
                        @click="noTagActions"
                      >
                        No tag ({{ howMuchNoTag }})
                      </button>
                    </MenuItem>
                    <!-- <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full mr-2 hover:bg-gray-200"
                        @click="betActions"
                      >
                        Actions with bet ({{ howMuchBet }})
                      </button>
                    </MenuItem> -->
                    <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full hover:bg-gray-200"
                        @click="resetFilter"
                      >
                        No filter
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full mr-2 hover:bg-gray-200"
                        @click="noBetActions"
                      >
                        No bet ({{ howMuchNoBet }})
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full mr-2 hover:bg-gray-200"
                        @click="aPcoins"
                      >
                        Ascending P-coins bet
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full mr-2 hover:bg-gray-200"
                        @click="bPcoins"
                      >
                        Descending P-coins bet
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="submit"
                        class="h-8 w-full mr-2 hover:bg-gray-200"
                        @click="aNumber"
                      >
                        Ascending #
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div id="myTimer" style=";display:none;" class="mr-3">
            <MiniTimer v-if="activeRound != null && activeRound.time != null" :active-round="activeRound" :dateNow="dateNow" ref="timer" />
        </div>
      </div>

      <div class=" md:flex justify-between" style="min-height: 80px;max-height: 150px">
        <div class="flex justify-between mt-3 overflow-x-auto max-height" style="min-height: 80px;">
          <div class="flex flex-wrap mb-3">
            <div
              v-for="tag in tags"
              :key="tag"
              style="background-color: #00cb49"
              class="rounded-lg mr-3 mb-3 flex justify-between px-2 h-8 p-1"
            >
              <p
                class="text-white mr-3 h-3"
                @click="filterTag(tag)"
                style="cursor: pointer"
              >
                {{ tag }}
              </p>
              <p
                @click="reading != true ? removeTag(tag) : ''"
                :style="reading != true ? 'cursor: pointer' : ''"
                class="text-white h-3"
              >
                <i v-if="reading != true" class="fa fa-times" aria-hidden="true"></i>
              </p>
            </div>
          </div>

          
        </div>

        <div class="h-8 mb-3">
          <p
            style="vertical-align: middle;"
            class="text-left"
          >
            POOL OF COINS :
          </p>
          <div class="flex flex-row-reverse">
            <div  v-if="isLived && isSubmit != 1 && (coalition !=1)" class="mr-2 h-12 w-28 text-center text-white font-semibold rounded-xl flex justify-center">
              <div v-if="actionsHeader" class="flex">
                <button
                  type="submit"
                  v-if="isLived && reading != true && isSubmit != 1 && (coalition !=1)"
                  style="background-color: #ff644e"
                  class="font-semibold text-white h-12 w-28 rounded-xl mr-2 text-xl"
                  @click="popupResetPoints"
                >
                  Reset
                </button>
              </div>
            </div>

            <div
              :style="
                'vertical-align: middle;line-height: 32px;background-color:' +
                poolColor
              "
              class="mr-4 h-12 w-28 text-center text-white font-semibold rounded-xl flex justify-center"
            >
              <p class="text-3xl mt-1">{{ restPool }}</p>
              <img class="ml-2 w-9 mb-1" src="/img/pcoins.svg" alt="" />
            </div>
          </div>
            
        </div>
        <AlertResetPopup
          @close-popup="closePopup"
          @reset-points="resetPoints"
          :text="popupMessage"
          v-show="isPopup"
        />
      </div>
      
      <div class="flex mt-10 md:mt-0">
        <div v-if="actions.length > 0" class="flex flex-row-reverse">
          <!-- v-if="isSubmit == 1 || !isLived" -->
          <p class="text-red-600 font-semibold mb-2 mt-2" id="is-submit" v-if="isSubmit == 1 || !isLived">
            This round is finished.
          </p>
        </div>

        <!-- v-if="allPoints" -->
        <div class="ml-4 flex flex-row-reverse">
          <p class="text-red-600 font-semibold mb-2 mt-2" v-if="allPoints">
            You have used too many P-coins.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import apiManager from "../datas/apiManager";
import AlertResetPopup from "./AlertResetPopup";
import MiniTimer from "./MiniTimer";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import _ from 'lodash'
export default {
  data: () => ({
    tags: [],
    actionsHeader: [],
    popupMessage: "",
    isPopup: false,
    activefilter : "Sort By",
    filterHeader : '',
  }),
  emits: [
    "removeTag",
    "filterTag",
    "reset-points",
    "submit",
    "noTagActions",
    "betActions",
    "resetFilter",
    "filterInput",
    "addTag",
    "gridView",
    "listView",
    "noBetActions",
    "aPcoins",
    "bPcoins",
    "aNumber",

  ],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    AlertResetPopup,
    MiniTimer,
  },
  props: {
    tagsList: Array,
    restPool: [String, Number],
    actions: [String, Array, Object],
    pool: [String, Number],
    allPoints: [Boolean],
    isLived: Boolean,
    howMuchNoTag: [Number],
    howMuchBet: [Number],
    howMuchNoBet: Number,
    isList : [Boolean, Number],
    filter: [String, Array, Object],
    coalition: [Boolean, Number],
    reading: [Boolean]
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    noBetActions() {
      this.activefilter ="No bet (" + this.howMuchNoBet + ")"
      this.$emit("noBetActions");
    },
    aNumber() {
      this.activefilter ="Ascending #"
      this.$emit("aNumber");
    },
    aPcoins() {
      this.activefilter ="Ascending P-coins bet"
      this.$emit("aPcoins");
    },
    bPcoins() {
      this.activefilter ="Descending P-coins bet"
      this.$emit("bPcoins");
    },
    listView() {
      this.$emit("listView");
    },
    gridView() {
      this.$emit("gridView");
    },
    addTag() {
      this.$emit("addTag");
    },
		filterInput: _.debounce((e, context) =>{
      let filter = e.target.value;
      context.$emit("filterInput", filter);
		}, 1000),
    // filterInput(e) {
    //   let filter = e.target.value;
    //   this.$emit("filterInput", filter);
    // },
    noTagActions() {
      this.activefilter ="No tag (" + this.howMuchNoTag + ")"
      this.$emit("noTagActions");
    },
    betActions() {
      this.$emit("betActions");
    },
    resetFilter() {
      this.activefilter ="Sort By"
      this.$emit("resetFilter");
    },
    removeTag(tag) {
      this.$emit("removeTag", tag);
    },
    filterTag(tag) {
      this.$emit("filterTag", tag);
    },
    closePopup() {
      this.isPopup = false;
    },
    resetPoints() {
      this.$emit("reset-points");
    },
    popupResetPoints() {
      this.isPopup = true;
      this.popupMessage = "Are you sure you want to reset your P-coins?";
    },
  },
  mounted() {
   

  },
  computed: {
		isSubmit() {
			return this.$store.state.account.is_submit
		},
    dateNow () {
      return this.$store.state.account.date_now
    },
    activeRound () {
      return this.$store.state.account.active_round
    },
    poolColor() {
      if (this.restPool < this.pool / 3) {
        return "#FF644E";
      }
      if (this.restPool < this.pool * (2 / 3)) {
        return "#0287D1";
      }

      return "#00CB49";
    },
    user() {
      return this.$store.state.account.data;
    },
  },
  beforeUpdate() {
    if (this.tagsList) {
      this.tags = this.tagsList;
    }
    if (this.actions) {
      this.actionsHeader = this.actions;
    }
  },
  created() {
    if (this.tagsList) {
      this.tags = this.tagsList;
    }
    if (this.actions) {
      this.actionsHeader = this.actions;
    }
  },
};
</script>