<template>
  <div
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
    style="z-index:80"
  >
    <div
      class="absolute bg-black opacity-20 inset-0 z-0"
      style="z-index:70"
      
      @click="closePopup()"
    ></div>
      <div>
      <img src="/img/popup.svg" style="width:27rem;transform: translate(0,7rem);z-index:90;position: relative;" alt="" class="m-auto" 
       >
      <div
        style="width: 40rem;height25rem;background-color:#0287D1;z-index:80;position: relative;"
        class="w-full max-w-6xl relative mx-auto my-auto rounded-xl shadow-lg p-12 "
      >
        <div class="h-full pt-20">
          <div class="text-center flex flex-auto justify-center">
            <p class="text-white text-3xl font-semibold">Submit</p>
          </div>
          <div class="text-center">
            <p class="ml-1 text-white text-lg">Are you sure you want to definitely submit?</p>
          </div>

          <!--footer-->
          <div class="mt-8 text-center space-x-4 md:block">
            <a
              class="text-xl mb-2 md:mb-0 px-8 py-3 text-sm shadow-sm font-semibold tracking-wider text-white rounded-full hover:shadow-lg hover:bg-gray-100"
              id="return"
              style="cursor: pointer;background-color:#FF644E"
              @click="closePopup()"
            >
              Not yet
            </a>
            <a
              @click="submit()"
              style="cursor: pointer;background-color:#00CB49"
              class="text-xl mb-2 md:mb-0 px-8 py-3 text-sm shadow-sm font-semibold tracking-wider rounded-full hover:shadow-lg hover:bg-gray-100"
              replace>Submit
            </a>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>


<script>
import apiManager from "../datas/apiManager";
import store from '../store/index'
import party from "party-js";
export default {
  components: {},
  emits: ["close-popup"],
  data: () => ({}),
  props: {
    score_id : [Number, String],
  },
  methods: {
    closePopup() {
       this.$emit("close-popup");
    },
    submit() {
      let data = {
        score_id : this.score_id
      }
      apiManager.submit(data).then(() => {
        // console.log(response);
      })
      
      var element = document.getElementById('party');
      party.confetti(element, {
        // returns a random number from min to max
        count: party.variation.range(100, 150)
      });

      store.commit('account/_set', { k: 'is_submit', v: 1 })
      this.$emit("close-popup");
    }
  },
  created() {},
  computed: {
    baseURL() {
      if (window.location.origin.includes("localhost")) {
        return "http://localhost:8000";
      } else {
        return process.env.VUE_APP_API_URL;
      }
    },
   
  },
};
</script>
