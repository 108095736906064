<template> 
  <LineChart :chartData="testData" :options="options" :height="height"/>
</template>

<script>
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
    components: { LineChart },
    props : {
        dataChart: {
            type: Array, Object,
        },
    },
    computed : {
        NumberRound() {
            let labels = []
            for (let index = 0; index < this.dataChart.length; index++) {
                labels.push('Round ' + (index+1))
                
            }
            return labels
        },
        testData() {
            let data = {
                labels: this.NumberRound,
                datasets: [
                    {
                        label: null,
                        data: this.dataChart,
                        borderColor: "#00CB49",
                        fill: {
                            target: 'origin',
                            above: '#bfe4cd',
                        }
                    },
                ],
            }
            return data
        },
        options() {
            let options = {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },    
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'P-coins'
                        },
                        ticks: {
                            stepSize: 1
                        }
                    }
                }   
            }
            return options
        },
        height() {
            return parseInt(150)
        }
        
    },
};
</script>
