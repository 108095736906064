<template>
  <div v-if="this.user != null && !isAdmin" class="">
    <Header/>
  </div>
  <router-view/>
</template>

<script>

import Header from './components/Header'
import taskManager from './datas/taskManager'
import store from './store/index'
export default {
  name: 'App',
  components: {
    Header
  },
  methods : {
    logout () {
      taskManager.logout()
    },
  },
  computed : {
    user () {
      return this.$store.state.account.data
    },
    isAdmin () {
      return window.location.href.includes('xRankingxLivex') ? true : false
    }
  },
  created() {
    store.commit('account/_set', { k: 'last_diary_id', v: null })
    store.commit('account/_set', { k: 'diaries', v: [] })
  }
  
}
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
