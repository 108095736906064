<template>
<div v-if="endedRounds.length>0 && !isError" class="pt-4 min-h-screen" style="background-color:#f2f9ff">
  <h1 class="mb-6 text-xl font-bold p-3 lg:m-10 rounded-xl text-white" style="background-color:#0287D1;">LIVE RANKING</h1>

  <div class="p-6 lg:p-10" :class="'w-full lg:w-7/12 lg:m-auto'">

    <div class="right bg-white p-4 rounded-xl mb-10 w-full">
      <h1 class="mb-6 text-xl font-bold text-left mx-6">General results</h1>
        <div class="mt-4 font-normal" v-for="(score) in sortedScores(lastRound.scores)" :key="score">
          <div class="flex justify-between mx-6 p-3 rounded-xl text-white border" :style="'border-color:#D5D5D5;background-color:' + backgroundColorRank(score.classement)">
            <p class="h-10 w-1/3 text-left pt-2" v-if="score.classement != 1 && score.classement != 2 && score.classement != 3">
              <span class=""
              :class="' border rounded-full w-10 h-8 p-1 px-2 ml-9'"
              :style="'color:' + 'white;' + ' border-color:' + ' white;'">
              {{score.classement}}
              </span>
            </p>
            <p class="h-10 w-1/3 relative" v-else>
              <img v-if="score.classement == 1" :class="'w-24 -bottom-2 absolute'" :src="'/img/rank' + (score.classement) + '.svg'" alt="">
              <img v-if="score.classement == 2" :class="'w-16 left-4 absolute'" style="bottom: -0.3rem;" :src="'/img/rank' + (score.classement) + '.svg'" alt="">
              <img v-if="score.classement == 3" :class="'w-16 left-4 absolute'" style="bottom: -0.3rem;" :src="'/img/rank' + (score.classement) + '.svg'" alt="">
            </p>
            <!-- :class="index+1 == 1 ? 'w-12' : index+1 ==  2 ? 'w-8' : 'w-3'"  -->

            <p :class="'font-semibold text-2xl mt-1' + ' w-1/3'" :style="'color:' + (score.classement != 1 && score.classement != 2 && score.classement != 3 ? '#0287D1;' : 'white;')">{{score.group.name}}</p>
            <p :class="'font-semibold text-2xl mt-1' + ' w-1/3'" :style="'color:' + (score.classement != 1 && score.classement != 2 && score.classement != 3 ? '#0287D1;' : 'white;')">{{getGlobal(score.global, score.fake)}} pts</p>
          </div>
        </div>
    </div>

    <div  v-for="(score) in sortedScores(lastRound.scores)" :key="score.id"  class="left bg-white p-4 rounded-xl mb-10" :class="'w-full'">
      <div class="flex justify-between">
        <div class="md:w-5/12">
          <h1 class="mb-6 text-xl text-left font-bold">Results {{score.group.name}}</h1>
          <div>
            <div class="mt-4 font-semibold">
              <div class="text-left p-3 text-white rounded-xl flex justify-between" style="background-color:#0287D1;">
                <p class="flex whitespace-nowrap">
                  <img class="w-8 mr-2" src="img/medal.png" alt="">
                  Your score
                </p>
                <p class="text-3xl">{{getGlobal(score.global, score.fake)}}</p>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-4 font-semibold">
              <div class="text-left p-3 text-white rounded-xl flex justify-between" style="background-color:#0287D1;">
                <p class="flex whitespace-nowrap">
                  <img class="w-8 mr-2" src="img/rank.png" alt="">
                  Rank
                </p>
                <p>
                  <span class="text-3xl">{{getRank(lastRound.scores, score.group.name)}}</span>
                  /{{lastRound.scores.length}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCoalition" class="w-6/12 md:w-5/12 flex flex-col">
          <img class="w-32 self-end" src="img/rank-illu-2.svg" alt="">
          <div class="w-full text-left font-semibold border-4 rounded-xl p-4" :style="'border-color:#0287D1;'">
            <p class="text-2xl" style="color:#00CB49">Coalition Bonus</p>
            <p style="color:#0287D1"><span class="text-6xl">{{getBonus(score.group.name)}}</span> pts</p>
          </div>
        </div>
        <div v-else class="">
          <img src="img/rank-illu.svg" class="h-40 md:h-56 md:mr-28 mt-8 md:mt-0" alt="">
        </div>
      </div>
      
      <!-- ! ICI il manquerai le round du score, verifier dans lapage ranking normal si pareil
      !Faire attention au header sur cette page il est bug -->
      
      <table class="w-full" style="border-collapse: collapse;">
        <thead>
          <tr class="border-b-2">
            <th class="py-4 text-left"><p style="color:#0287D1;">Stakeholders</p></th>
            <th class="py-4" v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <p :class="lastRound.id == round.id ? 'text-xl' :''" :style="lastRound.id == round.id ? 'color:#0287D1;' :'color:#D5D5D5;'">
                Round {{ifBeforeCoalition(endedRounds, round.number)}}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/planet.svg" alt="">
              <p class="w-20">Planet & Society</p>
            </td>
            <td v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.id ? ' w-12' :' w-8')" :src="smiley(getTeamScore(score, round.scores).mPlanet)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/buisiness.svg" alt="">
              <p class="w-20">Business Partner</p>
            </td>
            <td :class="lastRound.id == round.id ? 'text-3xl' :'text-xl'" v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.id ? ' w-12' :' w-8')" :src="smiley(getTeamScore(score, round.scores).mDistributors)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/consumers.svg" alt="">
              <p class="w-20 mt-3">Consumers</p>
            </td>
            <td :class="lastRound.id == round.id ? 'text-3xl' :'text-xl'" v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.id ? ' w-12' :' w-8')" :src="smiley(getTeamScore(score, round.scores).mClients)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/employees.svg" alt="">
              <p class="w-20 mt-3">Employees</p>
            </td>
            <td :class="lastRound.id == round.id ? 'text-3xl' :'text-xl'" v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.id ? ' w-12' :' w-8')" :src="smiley(getTeamScore(score, round.scores).mEmployees)" alt="">
            </td>
          </tr>
          <tr>
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/shareholder.svg" alt="">
              <p class="w-20 mt-3">Shareholders</p>
            </td>
            <td :class="lastRound.id == round.id ? 'text-3xl' :'text-xl'" v-for="round in endedRoundsWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.id ? ' w-12' :' w-8')" :src="smiley(getTeamScore(score, round.scores).bpShareholders.toFixed(2) == '-0.20' ? 0 : getTeamScore(score, round.scores).mShareholders)" alt="">
            </td>
          </tr>
        </tbody>
      </table>
     
    </div>
    
  </div>
  <h1 class="mb-6 text-xl font-bold p-3 m-10 rounded-xl text-white" style="background-color:#00CB49;">GLOBAL RANKING SCORE</h1>
  <div class="bg-white p-4 rounded-xl mt-10 m-8 lg:w-7/12 lg:m-auto">
    <RankingChart :data-chart="endedRounds" :numberRounds="numberRounds"/>
  </div>
</div>
<div class="text-xl font-semibold pt-6" v-else>{{errorMessage}}</div>
</template>

<script>
import apiManager from "../datas/apiManager";
import RankingChart from "../components/RankingChart.vue";
// import store from '../store/index'

export default {
  name: "Admin",
  components: {
    RankingChart,
  },
  data: () => ({
    isRoundNoStakeholders: false,
    numberRounds : 0,
    sessionStatus : 0,
    activeRound : null,
    allRounds : [],
    allEndedRounds : [],
    endedRounds : [],
    errorMessage : '',
    isError : false ,
    happyness : [
      { level : 0, smiley : 'img/smiley1.svg' },
      { level : 0.5, smiley : 'img/smiley2.svg' },
      { level : 0.7, smiley : 'img/smiley3.svg' },
      { level : 0.8, smiley : 'img/smiley4.svg' },
      { level : 0.9, smiley : 'img/smiley5.svg' },
    ]
  }),
  methods: {
    getGlobal(global, fake) {
      
      let score = (global < 0 || global == null) ? 0 : global
      if (score < fake) {
        score = fake
      }

      return score
    },
    ifBeforeCoalition(rounds, numberRound) {
      let number = numberRound
      rounds.forEach(round => {
        if (round.coalition == 1 && round.number == (numberRound + 1)) {
          number = numberRound + ' & ' + (numberRound + 1)
        }
        
      });

      return number

    },
    getBonus(userId) {
      let bonus = 0
      this.endedRounds.forEach(round => {
        if (round.coalition == true) {
          round.scores.forEach(score => {
            if (score.group.name == userId && score.bonus != null) {
              bonus += score.bonus
            }
          });
        }
      });
      
      return bonus
    },
    getTeamScore(group, scores) {
      let teamScore = null;

      scores.forEach(score => {
          if (score.group_id == group.group_id) {
            teamScore = score
          }
      });

      if(teamScore == null) {
        teamScore = {
          // id : tableRound.scores.length + 1,
          group_id : group.id,
          group : {
            name:group.name
          },
          global :  0 ,
          mPlanet : 0,
          mDistributors : 0,
          mClients : 0,
          mEmployees : 0,
          mShareholders : 0,
          bpShareholders : 0,
          bonus : 0,
          fake : 0,
        }
      }

      return teamScore
    },
    smiley(value) {

      let smiley = this.happyness[0].smiley

      this.happyness.forEach(level => {
        if (value >= level.level) {
          smiley = level.smiley
        }
        
      });

      return smiley

    },
    sortedScores(scores) {
      let sortedScores = scores

      sortedScores.sort(function compare(a, b) {
        if (a.global > b.global)
          return -1;
        if (a.global < b.global )
          return 1;
        return 0;
      });
      for(var i in sortedScores) {
        if (i != "length") {
           if(i != 0 && sortedScores[i].global == sortedScores[i-1].global)  // Si on a le même score que le précédent, on donne le même classement
            sortedScores[i].classement = sortedScores[i-1].classement;
          else                                            // Sinon on met bien l'index +1
            sortedScores[i].classement = parseInt(i)+parseInt(1);
        }
       
      }
      
      return sortedScores
    },
    getRank(scores,userId) {
      let sortedScores = this.sortedScores(scores)
      for(var i in sortedScores) {
        
        if (i != "length") {
          if(i != 0 && sortedScores[i].global == sortedScores[i-1].global)  // Si on a le même score que le précédent, on donne le même classement
            sortedScores[i].classement = sortedScores[i-1].classement;
          else                                            // Sinon on met bien l'index +1
            sortedScores[i].classement = parseInt(i)+parseInt(1);
        }
      }

      const index = sortedScores.find(score => {
        if (score.group.name == userId) {
          return score.classement
        }
      });

      return index.classement
      
    },
    backgroundColorRank (rank){

      if (rank == 1) {
        return  '#004C19'
      }

      if (rank == 2) {
        return  '#00CB49'
      }

      if (rank == 3) {
        return  '#00CB49'
      }

      return  '#FFFFF'

    }
  },
  computed: {
    endedRoundsWithoutCoa() {
      let rounds = []
      this.endedRounds.forEach(round => {
        if (round.coalition != 1 ) {
          rounds.push(round)
        }
      });

      return rounds
    },
    isCoalition(){
      let coalition = false
      if(this.activeRound != null && this.activeRound.coalition == true) {
        coalition = true
      }
      
      this.endedRounds.forEach(round => {
        if (round.coalition == true) {
          coalition = true
        }
        
      });

      // if (this.allEndedRounds[this.allEndedRounds.length -1].session.status == 30 && this.allEndedRounds[this.allEndedRounds.length -1].coalition == 1) {
      //   coalition = false
      // }
      return coalition
    },
    lastRound() {
      return this.endedRounds[this.endedRounds.length -1];
    },
  
   
  },
  async created() {
    document.title='POP Live Ranking'
    apiManager
      .getSettings()
      .then((response) => {
        let stake1 = 0
        let stake2 = 0
        let stake3 = 0
        let stake4 = 0
        let stake5 = 0

        response.data.data.forEach(setting => {
          if (setting.key == "stake1_seuil") {
            stake1 = setting.value
          }

          if (setting.key == "stake2_seuil") {
            stake2 = setting.value
          }

          if (setting.key == "stake3_seuil") {
            stake3 = setting.value
          }

          if (setting.key == "stake4_seuil") {
            stake4 = setting.value
          }

          if (setting.key == "stake5_seuil") {
            stake5 = setting.value
          }
        });

        this.happyness[0].level = stake1
        this.happyness[1].level = stake2
        this.happyness[2].level = stake3
        this.happyness[3].level = stake4
        this.happyness[4].level = stake5
    })

    apiManager
    .getForm()
    .then(() => {
      this.isError = false;
      this.errorMessage = "";
    })
    .catch((err) => {
      if (err) {
        this.isError = true;
        this.errorMessage = "There is no active form";
      }
    });

    //refresh du score à chaque f5
    let dataCalcul = {
      group_id : null,
      round_id : null
    }
    await apiManager.calculate(dataCalcul)


    let [ activeResult, endendResult] = await Promise.all([
      apiManager.getLastRound(),
      apiManager.getRankingAdmin().catch((err) => {
        if (err) {
          console.log(err)
          this.isError = true;
          this.errorMessage =
            "Your ranking is not yet available";
        }
      })
    ]);
  
    this.isError = false;
    this.errorMessage = "";
    this.activeRound = activeResult.data.activeRound

   

    this.numberRounds = endendResult.data.length
    let endedRoundsNoActive = Object.assign([], endendResult.data.endedRounds.data)

    this.sessionStatus = endendResult.data.sessionStatus

    var endedRounds = [];
    for ( var index in endedRoundsNoActive ) {
      endedRounds[index] = endedRoundsNoActive[index]; 
    }

    if (this.activeRound) {
      // allEndedRounds.push(this.activeRound)
      endedRounds.push(this.activeRound)
    }
    //idem
    let tableRounds = []
    for (const key in endedRounds) {
      tableRounds.push(endedRounds[key])
    }

    tableRounds.sort(function compare(a, b) {
      if (a.number < b.number)
        return -1;
      if (a.number > b.number )
        return 1;
      return 0;
    });

    
    //pour chaque round
    let previousRound = null
    tableRounds.forEach(tableRound => {

      //je regarde quelles teams on un score, je stocke
      let teamsRound = []
      tableRound.scores.forEach(score => {
        score.global = this.getGlobal(score.global, score.fake)
        
        teamsRound.push(score.group_id)
      });

      let teamsNoScore = []
      //Si je n'ai pas autant de score que de teams dans ma session
      if (teamsRound.length < endendResult.data.groups.length) {
        //je regarde quelles teams me manquent
        endendResult.data.groups.forEach(group => {
          if (!teamsRound.includes(group.id)) {
            teamsNoScore.push(group)
          }
          
        });
      }
      
      //Il faut que j'ajoute les scores manquants avec juste les données donc j'ai besoin pour l'affichage 
      teamsNoScore.forEach(group => {

        //si ma team avait un score précédant, je vais utilisé ces données sinon 0
        let previousScore = null 
        if (previousRound != null) {
          previousRound.scores.forEach(score => {
            if (score.group_id == group.id) {
              previousScore = score
            }
          });
        }

        let score = {
          // id : tableRound.scores.length + 1,
          group_id : group.id,
          group : {
            name:group.name
          },
          global : previousScore != null ? previousScore.global : 0 ,
          round_id : tableRound.id,
          mPlanet : previousScore != null ? previousScore.mPlanet : 0,
          mDistributors : previousScore != null ? previousScore.mDistributors : 0,
          mClients : previousScore != null ? previousScore.mClients : 0,
          mEmployees : previousScore != null ? previousScore.mEmployees : 0,
          mShareholders : previousScore != null ? previousScore.mShareholders : 0,
          bpShareholders : previousScore != null ? previousScore.bpShareholders : 0,
          bonus : previousScore != null ? previousScore.bonus : 0,
          fake : previousScore != null ? previousScore.fake : 0,
        }
        tableRound.scores.push(score)
      });

      previousRound = tableRound
    });


    this.endedRounds = tableRounds
    // this.allEndedRounds = tableAllRounds

    if (tableRounds.length > 0/*  && tableAllRounds > 0 */) {
      this.errorMessage = "";
    } else {
      this.errorMessage = "Your ranking is not yet available";
    }
     
      
  },
  watch: {
    
  }

};
</script>

<style scoped>
</style>
