<template>
  <nav class="w-max-screen" style="max-height:250px">
    <!-- Primary Navigation Menu -->

    <div class="flex justify-between w-full" style="max-height:250px">
      <div class="flex-shrink-0 w-full flex items-center" style="z-index:30;position: relative;">
        <!-- Logo -->
        
        <router-link to="/" class="">
          <img class="justify-self-start block text-gray-600 lg:block" style="max-height:210px;min-height:180px;object-fit: cover;object-position: 0 100%;" :src="'/img/header.svg'">
        </router-link>

        <div class="h-full absolute sm:right-96" style="max-height:250px;">
          <div class="ml-10 mt-20 lg:ml-0 lg:mt-0 w-full h-full">
            <Timer v-if="activeRound != null && activeRound.time != null" :active-round="activeRound" :dateNow="dateNow" ref="timer" />
          </div>
          <div class="relative ml-10 lg:ml-0 bottom-44 lg:bottom-24" style="" id="party">
            <img v-if="isSubmit != 1 && activeRound != null && reading != true" @click="submit()" :style="'cursor: pointer;'" class="block text-gray-600 mt-1" :src="'/img/submit.png'">
            <img v-else class="block text-gray-600 mt-1" style="opacity: 0;" :src="'/img/submit.png'">
          </div>
        </div>

      </div>

      <div class="mt-4 right-0" style="z-index:60;position: absolute;top:0.5rem;width:380px;">
        <p>YOUR TEAM</p>
        <p class="font-semibold text-lg" style="color:#0287D1;">{{user.group.name.toUpperCase()}}</p>
        <p class="font-semibold text-lg" style="color:#0287D1;">({{reading ? 'View' : 'Edit'}})</p>

        <!-- Settings Dropdown -->
        <div class="sm:flex sm:items-center sm:ml-6 mt-4 right-16" style="z-index:70;position: absolute;width:250px;">
          <Menu as="div" class="relative inline-block text-left" style="width:250px;">
            <div>
              <MenuButton class="flex items-center justify-between border border-gray-300 shadow-sm bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                          :style="itemHeader" style="width:250px;border-radius:10px!important;padding:0 12px !important;height:45px;font-size: 1.1em;">
                <p>{{ActiveRoute}}</p>
                <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true"/>
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style="width:250px;">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <router-link @click="changeActiveMenu('Game')"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
                                to="/"
                                replace>Game
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link @click="changeActiveMenu('Ranking')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
                                to="/ranking"
                                replace>Ranking
                    </router-link>
                  </MenuItem>

                  <MenuItem v-slot="{ active }" v-if="user.group.malus == null || user.group.malus == 0">
                    <a :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
                                href="/library"
                                @click="popupLibrary($event)"
                                replace>Library
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-else>
                    <router-link @click="changeActiveMenu('Library')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
                                to="/library"
                                replace>Library
                    </router-link>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <router-link :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']"
                                to="/login"
                                @click="logout()"
                                replace>Logout
                    </router-link>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

  </nav>

  <LibraryPopup @close-popup="closePopup" v-show="isLibrary" :malus="user.group.malus" :library_cost="library_cost" :rest_pool="rest_pool" :reading="user.status=='view' || user.status=='reading'"/>
  <SubmitPopup @close-popup="closePopupSubmit" v-show="showSubmit" :score_id="score_id"/>

</template>

<script>
import taskManager from '../datas/taskManager'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import LibraryPopup from './LibraryPopup'
import SubmitPopup from './SubmitPopup'
import apiManager from "../datas/apiManager"; 
import Timer from "./Timer";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    LibraryPopup,
    SubmitPopup,
    Timer

  },
  data: () => ({
    activeMenu : 'Game',
    styleNotifBadge: 'position: absolute;left: 4em;bottom: 2em;padding: 6px;border-radius: 100%;background: #D3007D;font-weight: bold;color: white;width: 2em;height: 2em;',
    itemHeader: 'background: #FFFFFF;box-shadow: 0px 4px 16px rgb(84 141 166 / 10%);border-radius: 80px;padding: 0 32px;height: 4em;margin: auto 10px;',
    isLibrary : false,
    showSubmit : 0

  }),
  props : {
  },
  methods: {
    changeActiveMenu(activeMenu) {
      this.activeMenu = activeMenu;
    },
    //todo
    closePopupSubmit() {
      this.showSubmit = 0;
    },
    submit() {
      this.showSubmit = 1;
    },
    logout () {
      taskManager.logout()
    },
    popupLibrary(e) {
      if (this.library_cost == 0) {

        if(this.user.group.malus == null || this.user.group.malus == 0) {
          let data = {
            malus: this.user.group.malus,
            library_cost: 0,
            score_id: this.score_id,
            team_id : this.user.id
          };
          this.$store.state.account.data.group.malus = 0

          apiManager.payLibrary(data).then((response) => {
            console.log(response);
            // store.commit('account/_set', { k: 'pay', v: 1 })
          });
        }
        this.$router.push('/library')
      } else {
        e.preventDefault()
        if(this.activeRound != null && !this.isSubmit) {
          this.isLibrary =true
        }
      }
      
    },
    closePopup() {
      this.isLibrary =false
    },
   
  },
  // mounted () {
  //   console.log(this.$router.currentRoute.value.name)
  //   this.changeActiveMenu(this.$router.currentRoute.value.name == 'Form' ? 'Game' : this.$router.currentRoute.value.name)
  // },
  computed : {
		isSubmit() {
			return this.$store.state.account.is_submit
		},
    user () {
      return this.$store.state.account.data
    },
    ActiveRoute() {
      return this.$router.currentRoute.value.name == 'Form' ? 'Game' : this.$router.currentRoute.value.name
    },
    baseURL () {
      if (window.location.origin.includes('localhost')) {
        return 'http://localhost:8000'
      } else {
        return process.env.VUE_APP_API_URL
      }
    },
    rest_pool () {
      return this.$store.state.account.rest_pool
    },
    library_cost () {
      return this.$store.state.account.library_cost
    },
    score_id () {
      return this.$store.state.account.score_id
    },
    activeRound () {
      return this.$store.state.account.active_round
    },
    dateNow () {
      return this.$store.state.account.date_now
    },
    reading () {
      return this.$store.state.account.reading
    },
    // actualTimer () {
    //   return this.$store.state.account.actual_timer
    // }
  }
}
</script>

<style scoped>
#headlessui-menu-button-1{
  border-radius:0px !important
}
</style>