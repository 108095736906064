<template>
<div v-if="endedRounds.length>0 && !isError && !isErrorForm" class="pt-4 min-h-screen" style="background-color:#f2f9ff">
  <h1 class="mb-6 text-xl font-bold p-3 lg:m-10 rounded-xl text-white" style="background-color:#0287D1;">RANKING GLOBAL SCORE</h1>

  <div class="p-6 lg:p-10" :class="'lg:flex w-full'">
    <div class="left bg-white p-4 rounded-xl" :class="'md:w-6/12 lg:w-7/12'">
      <div class="flex justify-between">
        <div class="md:w-5/12">
          <h1 class="mb-6 text-xl text-left font-bold">Your results</h1>
          <div>
            <div class="mt-4 font-semibold" v-for="(score) in sortedScores(lastRound.scores)" :key="score">
              <div class="text-left p-3 text-white rounded-xl flex justify-between" style="background-color:#0287D1;" v-if="score.group_id == user.group.id">
                <p class="flex whitespace-nowrap">
                  <img class="w-8 mr-2" src="img/medal.png" alt="">
                  Your score
                </p>
                <p class="text-3xl">{{getGlobal(score.global, score.fake)}}</p>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-4 font-semibold" v-for="(score) in sortedScores(lastRound.scores)" :key="score.id">
              <div class="text-left p-3 text-white rounded-xl flex justify-between" style="background-color:#0287D1;" v-if="score.group_id == user.group.id">
                <p class="flex whitespace-nowrap">
                  <img class="w-8 mr-2" src="img/rank.png" alt="">
                  Your rank
                </p>
                <p>
                  <span class="text-3xl">{{getRank(lastRound.scores, user.group.id)}}</span>
                  /{{lastRound.scores.length}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCoalition" class="w-6/12 md:w-5/12 flex flex-col">
          <img class="w-32 self-end" src="img/rank-illu-2.svg" alt="">
          <div class="w-full text-left font-semibold border-4 rounded-xl p-4" :style="'border-color:#0287D1;'">
            <p class="text-2xl" style="color:#00CB49">Coalition Bonus</p>
            <p style="color:#0287D1"><span class="text-6xl">{{getBonus(user.group.id)}}</span> pts</p>
          </div>
        </div>
        <div v-else class="">
          <img src="img/rank-illu.svg" class="h-40 md:h-56 md:mr-28 mt-8 md:mt-0" alt="">
        </div>
      </div>
      
      <table v-if="isStakeholders" class="w-full" style="border-collapse: collapse;">
        <thead>
          <tr class="border-b-2">
            <th class="py-4 text-left"><p style="color:#0287D1;">Stakeholders</p></th>
            <th class="py-4" v-for="score in userScoresWithoutCoa" :key="score.score">
              <p :class="lastRound.id == score.round_id ? 'text-xl' :''" :style="lastRound.id == score.round.id ? 'color:#0287D1;' :'color:#D5D5D5;'">
                Round {{ifBeforeCoalition(userScores, score.round.number)}}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/planet.svg" alt="">
              <p class="w-20">Planet & Society</p>
            </td>
            <td v-for="round in userScoresWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.round.id ? ' w-12' :' w-8')" :src="smiley(round.score.mPlanet)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/buisiness.svg" alt="">
              <p class="w-20">Business Partner</p>
            </td>
            <td :class="lastRound.id == round.round.id ? 'text-3xl' :'text-xl'" v-for="round in userScoresWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.round.id ? ' w-12' :' w-8')" :src="smiley(round.score.mDistributors)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/consumers.svg" alt="">
              <p class="w-20 mt-3">Consumers</p>
            </td>
            <td :class="lastRound.id == round.round.id ? 'text-3xl' :'text-xl'" v-for="round in userScoresWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.round.id ? ' w-12' :' w-8')" :src="smiley(round.score.mClients)" alt="">
            </td>
          </tr>
          <tr class="border-b-2">
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/employees.svg" alt="">
              <p class="w-20 mt-3">Employees</p>
            </td>
            <td :class="lastRound.id == round.round.id ? 'text-3xl' :'text-xl'" v-for="round in userScoresWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.round.id ? ' w-12' :' w-8')" :src="smiley(round.score.mEmployees)" alt="">
            </td>
          </tr>
          <tr>
            <td class="flex py-4">
              <img class="mr-2 w-12" src="/img/shareholder.svg" alt="">
              <p class="w-20 mt-3">Shareholders</p>
            </td>
            <!--! cas ou shareholder passe en max value et donc bp passe à -0,20  -->
            <td :class="lastRound.id == round.round.id ? 'text-3xl' :'text-xl'" v-for="round in userScoresWithoutCoa" :key="round.id">
              <img :class="'m-auto' + (lastRound.id == round.round.id ? ' w-12' :' w-8')" :src="smiley(round.score.bpShareholders.toFixed(2) == '-0.20' ? 0 : round.score.mShareholders)" alt="">
            </td>
          </tr>
        </tbody>
      </table>
     
    </div>
    <div class="right bg-white p-4 rounded-xl mt-10 lg:ml-10 lg:mt-0 md:6/12 lg:w-5/12">
      <h1 class="mb-6 text-xl font-bold text-left mx-6">General results</h1>
        <div class="mt-4 font-normal" v-for="(score) in sortedScores(lastRound.scores)" :key="score">
          <div class="flex justify-between mx-6 p-3 rounded-xl text-white border" :style="'border-color:#D5D5D5;background-color:' + backgroundColorRank(score.classement, score.group_id)">
            <p class="h-10 w-1/3 text-left pt-2" v-if="score.classement != 1 && score.classement != 2 && score.classement != 3">
              <span class=""
              :class="(score.group_id == user.group.id ? 'font-semibold' : '') + ' border rounded-full w-10 h-8 p-1 px-2 ml-9'"
              :style="'color:' + (score.group_id != user.group.id ? '#0287D1;' : 'white;') + ' border-color:' + (score.group_id != user.group.id ? '#0287D1;' : ' white;')">
              {{score.classement}}
              </span>
            </p>
            <p class="h-10 w-1/3 relative" v-else>
              <img v-if="score.classement == 1" :class="'w-24 -bottom-2 absolute'" :src="'/img/rank' + (score.classement) + (score.group_id != user.group.id ? '' : 'b') + '.svg'" alt="">
              <img v-if="score.classement == 2" :class="'w-16 left-4 absolute'" style="bottom: -0.3rem;" :src="'/img/rank' + (score.classement) + (score.group_id != user.group.id ? '' : 'b') + '.svg'" alt="">
              <img v-if="score.classement == 3" :class="'w-16 left-4 absolute'" style="bottom: -0.3rem;" :src="'/img/rank' + (score.classement) + (score.group_id != user.group.id ? '' : 'b') + '.svg'" alt="">
            </p>
            <!-- :class="index+1 == 1 ? 'w-12' : index+1 ==  2 ? 'w-8' : 'w-3'"  -->

            <p :class="(score.group_id == user.group.id ? 'font-semibold text-2xl mt-1' : 'mt-2' )+ ' w-1/3'" :style="'color:' + (score.group_id != user.group.id && score.classement != 1 && score.classement != 2 && score.classement != 3 ? '#0287D1;' : 'white;')">{{score.group.name}}</p>
            <p :class="(score.group_id == user.group.id ? 'font-semibold text-2xl mt-1' : 'mt-2' )+ ' w-1/3'" :style="'color:' + (score.group_id != user.group.id && score.classement != 1 && score.classement != 2 && score.classement != 3 ? '#0287D1;' : 'white;')">{{getGlobal(score.global, score.fake)}} pts</p>
          </div>
        </div>
    </div>
  </div>
  <h1 class="mb-6 text-xl font-bold p-3 m-10 rounded-xl text-white" style="background-color:#00CB49;">GLOBAL RANKING SCORE</h1>
  <div class="bg-white p-4 rounded-xl mt-10 lg:ml-10 lg:mt-0 m-10">
    <RankingChart :data-chart="endedRounds" :numberRounds="numberRounds"/>
  </div>
</div>
<div class="text-xl font-semibold pt-6" v-if="isError">{{errorMessage}}</div>
<div class="text-xl font-semibold pt-6" v-if="isErrorForm">{{errorMessageForm}}</div>
</template>

<script>
import apiManager from "../datas/apiManager";
import RankingChart from "../components/RankingChart.vue";
import store from '../store/index'

export default {
  name: "Ranking",
  components: {
    RankingChart,
  },
  data: () => ({
    isRoundNoStakeholders: false,
    numberRounds : 0,
    sessionStatus : 0,
    activeRound : null,
    allRounds : [],
    allEndedRounds : [],
    endedRounds : [],
    errorMessage : '',
    errorMessageForm : '',
    isError : false ,
    isErrorForm : false ,
    happyness : [
      { level : 0, smiley : 'img/smiley1.svg' },
      { level : 0.5, smiley : 'img/smiley2.svg' },
      { level : 0.7, smiley : 'img/smiley3.svg' },
      { level : 0.8, smiley : 'img/smiley4.svg' },
      { level : 0.9, smiley : 'img/smiley5.svg' },
    ]
  }),
  methods: {
    getGlobal(global, fake) {
      let score = (global < 0 || global == null) ? 0 : global
      if (score < fake) {
        score = fake
      }

      return score
    },
    ifBeforeCoalition(scores, numberRound) {
      let number = numberRound
      scores.forEach(score => {
        if (score.round.coalition == 1 && score.round.number == (numberRound + 1)) {
          number = numberRound + ' & ' + (numberRound + 1)
        }
        
      });

      return number

    },
    getBonus(userId) {
      let bonus = 0

      if (this.activeRound) {
        this.activeRound.scores.forEach(score => {
          if (score.group_id == userId && score.bonus != null) {
            bonus = score.bonus
          }
        });
      } 

      this.allEndedRounds.forEach(round => {
        if (round.coalition == true) {
          round.scores.forEach(score => {
            if (score.group_id == userId && score.bonus != null) {
              bonus += score.bonus
            }
          });
        }
      });
      
      return bonus
    },
    smiley(value) {
      let smiley = this.happyness[0].smiley
      this.happyness.forEach(level => {
        if (value >= level.level) {
          smiley = level.smiley
        }
        
      });

      return smiley

    },
    sortedScores(scores) {
      let sortedScores = scores

      sortedScores.sort(function compare(a, b) {
        if (a.global > b.global)
          return -1;
        if (a.global < b.global )
          return 1;
        return 0;
      });

      for(var i in sortedScores) {
        
        if (i != "length") {
          if(i != 0 && sortedScores[i].global == sortedScores[i-1].global)  // Si on a le même score que le précédent, on donne le même classement
            sortedScores[i].classement = sortedScores[i-1].classement;
          else                                            // Sinon on met bien l'index +1
            sortedScores[i].classement = parseInt(i)+parseInt(1);
        }
      }
      
      return sortedScores
    },
    getRank(scores,userId) {
      let sortedScores = this.sortedScores(scores)
      for(var i in sortedScores) {
        
        if (i != "length") {
          if(i != 0 && sortedScores[i].global == sortedScores[i-1].global)  // Si on a le même score que le précédent, on donne le même classement
            sortedScores[i].classement = sortedScores[i-1].classement;
          else                                            // Sinon on met bien l'index +1
            sortedScores[i].classement = parseInt(i)+parseInt(1);
        }
      }

      const index = sortedScores.find(score => {
        if (score.group_id == userId) {
          return score.classement
        }
      });

      return index.classement
      
    },
    backgroundColorRank (rank, group){
      if (group == this.user.group.id) {
        return  '#0287D1'
      }

      if (rank == 1) {
        return  '#004C19'
      }

      if (rank == 2) {
        return  '#00CB49'
      }

      if (rank == 3) {
        return  '#00CB49'
      }

      return  '#FFFFF'

    },
    refreshData() {
      apiManager
        .getLastRound()
        .then((response) => {
          this.activeRound = response.data.activeRound
          store.commit('account/_set', { k: 'active_round', v: response.data.activeRound })
          
          store.commit('account/_set', { k: 'date_now', v: response.data.dateNow })

          // store.commit('account/_set', { k: 'is_submit', v: 1 })
          
      })
    }
  },
  computed: {
    isCoalition(){
      let coalition = false
      // if(this.activeRound != null && this.activeRound.coalition == true) {
      //   coalition = true
      // }
      
      this.allEndedRounds.forEach(round => {
        if (round.coalition == true) {
          coalition = true
        }
        
      });

      if (this.sessionStatus == 30 && this.allEndedRounds[this.allEndedRounds.length -1].coalition == 1) {
        coalition = false
      }
      return coalition
    },
    isStakeholders() {
      if (this.isRoundNoStakeholders) {
        return false
      }

      if (this.activeRound && this.activeRound.is_stakeholders == 0) {
        return false
      }

      if (this.activeRound && this.activeRound.is_stakeholders == 1) {
        return true
      }

      return true
    },
    user() {
      return this.$store.state.account.data;
    },
    userScoresWithoutCoa() {
      let userScores = []
      this.endedRounds.forEach(round => {
        if (round.coalition != 1) {
          round.scores.forEach(score => {
            if (score.group_id == this.user.group.id) {
              userScores.push({
                round : round,
                score : score
              })
            }
          });
        }
       
        
      });
      return userScores;
    },
    userScores() {
      let userScores = []
      this.endedRounds.forEach(round => {
        round.scores.forEach(score => {
          if (score.group_id == this.user.group.id) {
            userScores.push({
              round : round,
              score : score
            })
          }
        });
        
      });
      return userScores;
    },
    lastRound() {
      return this.endedRounds[this.endedRounds.length -1];
    },
  
   
  },
  async created() {

    this.refreshData()

		this.interval = setInterval(() => {
			this.refreshData()
		}, 7000)

    //get all rounds from active or last session
    if(this.user) {
      let data = {
        group_id : this.user.group.id
      };

      apiManager.getLoggedUser(data).then((response) => {
        this.$store.state.account.data.group.malus = response.data.malus
      });

			//refresh du score à chaque f5
      let dataCalcul = {
        group_id : this.user.group.id,
        round_id : null
      }
      let score = await apiManager.calculate(dataCalcul)
      store.commit('account/_set', { k: 'last_diary_id', v: score.data.last_diary_id })
			
    }

    apiManager
      .getSettings()
      .then((response) => {
        let stake1 = 0
        let stake2 = 0
        let stake3 = 0
        let stake4 = 0
        let stake5 = 0

        response.data.data.forEach(setting => {
          if (setting.key == "stake1_seuil") {
            stake1 = setting.value
          }

          if (setting.key == "stake2_seuil") {
            stake2 = setting.value
          }

          if (setting.key == "stake3_seuil") {
            stake3 = setting.value
          }

          if (setting.key == "stake4_seuil") {
            stake4 = setting.value
          }

          if (setting.key == "stake5_seuil") {
            stake5 = setting.value
          }
        });

        this.happyness[0].level = stake1
        this.happyness[1].level = stake2
        this.happyness[2].level = stake3
        this.happyness[3].level = stake4
        this.happyness[4].level = stake5
    })
  

    apiManager
      .getForm()
      .then(() => {
        this.isErrorForm = false;
        this.errorMessageForm = "";
      })
      .catch((err) => {
        if (err) {
          this.isErrorForm = true;
          this.errorMessageForm = "There is no active form";
        }
      });

    apiManager
      .getRanking(this.user.group.id)
      .then((response) => {
        this.numberRounds = response.data.length

        if (response.data.endedRounds) {
          let endedRounds = Object.assign([], response.data.endedRounds.data)
          //J'ai besoin de tous les round, y compris celui en cours pour bien gerer l'affichage du bonus coalition....
          let allEndedRounds = Object.assign([], response.data.endedRounds.data)
          //Je range mes rounds
          let tableAllRounds = []
          for (const key in allEndedRounds) {
            tableAllRounds.push(allEndedRounds[key])
          }

          //je tris selon numéro du round
          tableAllRounds.sort(function compare(a, b) {
            if (a.number < b.number)
              return -1;
            if (a.number > b.number )
              return 1;
            return 0;
          });

          this.sessionStatus = response.data.sessionStatus


          //Si le dernier round est pauded, je l'enlève car les élève ne doivent pas le voir
          if (response.data.sessionStatus == 30) {
            this.isRoundNoStakeholders = endedRounds[endedRounds.length -1].is_stakeholders == 0
            endedRounds.pop()
          } else {
            this.isRoundNoStakeholders = false
          }

          //idem
          let tableRounds = []
          for (const key in endedRounds) {
            tableRounds.push(endedRounds[key])
          }


          tableRounds.sort(function compare(a, b) {
            if (a.number < b.number)
              return -1;
            if (a.number > b.number )
              return 1;
            return 0;
          });


          
          //pour chaque round
          let previousRound = null
          tableRounds.forEach(tableRound => {

            //je regarde quelles teams on un score, je stocke
            let teamsRound = []
            
            tableRound.scores.forEach(score => {
              score.global = this.getGlobal(score.global, score.fake)
              teamsRound.push(score.group_id)
            });

            let teamsNoScore = []
            //Si je n'ai pas autant de score que de teams dans ma session
            if (teamsRound.length != response.data.groups.length) {
              //je regarde quelles teams me manquent
              response.data.groups.forEach(group => {
                if (!teamsRound.includes(group.id)) {
                  teamsNoScore.push(group)
                }
                
              });
            }
            
            //Il faut que j'ajoute les scores manquants avec juste les données donc j'ai besoin pour l'affichage 
            teamsNoScore.forEach(group => {

              //si ma team avait un score précédant, je vais utilisé ces données sinon 0
              let previousScore = null 
              if (previousRound != null) {
                previousRound.scores.forEach(score => {
                  if (score.group_id == group.id) {
                    previousScore = score
                  }
                });
              }

          
              let score = {
                // id : tableRound.scores.length + 1,
                group_id : group.id,
                group : {
                  name:group.name
                },
                global : previousScore != null ? previousScore.global : 0 ,
                round_id : tableRound.id,
                mPlanet : previousScore != null ? previousScore.mPlanet : 0,
                mDistributors : previousScore != null ? previousScore.mDistributors : 0,
                mClients : previousScore != null ? previousScore.mClients : 0,
                mEmployees : previousScore != null ? previousScore.mEmployees : 0,
                mShareholders : previousScore != null ? previousScore.mShareholders : 0,
                bpShareholders : previousScore != null ? previousScore.bpShareholders : 0,
                bonus : previousScore != null ? previousScore.bonus : 0,
                fake : previousScore != null ? previousScore.fake : 0,
              }
              tableRound.scores.push(score)
            });

            previousRound = tableRound
          });


          //pour chaque round
          let previousAllRound = null
          tableAllRounds.forEach(tableAllRound => {

            //je regarde quelles teams on un score, je stocke
            let teamsAllRound = []
            tableAllRound.scores.forEach(score => {
              score.global = this.getGlobal(score.global, score.fake)
              teamsAllRound.push(score.group_id)
            });

            let teamsAllNoScore = []
            //Si je n'ai pas autant de score que de teams dans ma session
            if (teamsAllRound.length < response.data.groups.length) {

              //je regarde quelles teams me manquent
              response.data.groups.forEach(group => {
                if (!teamsAllRound.includes(group.id)) {
                  teamsAllNoScore.push(group)
                }
                
              });
            }
            
            //Il faut que j'ajoute les scores manquants avec juste les données donc j'ai besoin pour l'affichage 
            teamsAllNoScore.forEach(group => {

              //si ma team avait un score précédant, je vais utilisé ces données sinon 0
              let previousAllScore = null 
              if (previousAllRound != null) {
                previousAllRound.scores.forEach(score => {
                  if (score.group_id == group.id) {
                    previousAllScore = score
                  }
                });
              }


              let scoreAll = {
                // id : tableRound.scores.length + 1,
                group_id : group.id,
                group : {
                  name:group.name
                },
                global : previousAllScore != null ? previousAllScore.global : 0 ,
                round_id : tableAllRound.id,
                mPlanet : previousAllScore != null ? previousAllScore.mPlanet : 0,
                mDistributors : previousAllScore != null ? previousAllScore.mDistributors : 0,
                mClients : previousAllScore != null ? previousAllScore.mClients : 0,
                mEmployees : previousAllScore != null ? previousAllScore.mEmployees : 0,
                mShareholders : previousAllScore != null ? previousAllScore.mShareholders : 0,
                bpShareholders : previousAllScore != null ? previousAllScore.bpShareholders : 0,
                bonus : previousAllScore != null ? previousAllScore.bonus : 0,
                fake : previousAllScore != null ? previousAllScore.fake : 0,
              }
              tableAllRound.scores.push(scoreAll)
            });

            previousAllRound = tableAllRound
          });

          this.endedRounds = tableRounds
          this.allEndedRounds = tableAllRounds

          if (tableRounds.length > 0 && tableAllRounds.length > 0) {
            this.isError = false;
            this.errorMessage = "";
          } else {
            this.isError = true;
            this.errorMessage = "Your ranking is not yet available";
          }

        } else {
          this.isError = true;
          this.errorMessage = "Your ranking is not yet available";
        }

        
      })
      .catch((err) => {
        if (err) {
          console.log(err)
          this.isError = true;
          this.errorMessage =
            "The ranking is currently unavailable.";
        }
      });
      
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  watch: {
    
  }

};
</script>

<style scoped>
</style>
